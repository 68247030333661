import { Trans, useLingui } from "@lingui/react/macro";
import { IconButton, PlainButton } from "@components/Button/Button";
import styles from "./ActivityTemplatesOgNvk.module.scss";
import PlusIcon from "@components/icons/PlusIcon";
import { templates } from "./templatesOgNvk";
import { Heading } from "@components/Heading/Heading";
import CrossIcon from "@components/icons/CrossIcon";
import { useNavigate, useParams } from "react-router";
import { useId } from "react";
import { Template } from "./TemplateOgNvk";
import { useMatchPatientTypes } from "../templateUtils";
import { z } from "zod";

export const ActivityTemplatesOgNvk = () => {
  const { t } = useLingui();
  const navigate = useNavigate();

  const listHeadingId = useId();

  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());

  const { isPatientTypesMatching } = useMatchPatientTypes(patientId);

  const listedTemplates = Object.entries(templates).filter(([_, template]) => {
    return (
      template.templateOptions.showInList &&
      isPatientTypesMatching(template.templateOptions.patientTypes)
    );
  });

  const sortedTemplates = Object.fromEntries(
    listedTemplates.sort(([, aTemplate], [, bTemplate]) =>
      aTemplate.title.localeCompare(bTemplate.title),
    ),
  );

  return (
    <article className={styles.activityTemplates}>
      <div className={styles.headingRow}>
        <Heading level="h1" size="h2">
          <Trans>Ny aktivitet</Trans>
        </Heading>
        <IconButton aria-label={t`Stäng`} onClick={() => navigate("..")}>
          <CrossIcon />
        </IconButton>
      </div>
      <div className={styles.createCustomActivityButton}>
        <PlainButton onClick={() => navigate("../new")}>
          <PlusIcon />
          <Trans>Skapa anpassad aktivitet</Trans>
        </PlainButton>
      </div>

      <Heading
        id={listHeadingId}
        level="h2"
        weight="regular"
        className={styles.listHeading}
      >
        <Trans>Välj mall</Trans>
      </Heading>
      <ul className={styles.list} aria-labelledby={listHeadingId}>
        <div className={styles.listGroup}>
          {Object.entries(sortedTemplates).map(([name, template]) => {
            return (
              <li key={name}>
                <Template template={template} />
              </li>
            );
          })}
        </div>
      </ul>
    </article>
  );
};
