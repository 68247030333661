/* eslint-disable lingui/no-unlocalized-strings */
// Don't translate user-generated content
import {
  activityFrequencySchema,
  customRecurrenceIntervalUnitSchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/schedulingModels";
import { categorySchema, timeOfDaySchema } from "@models/activities";
import { medicalCompetenceSchema } from "@models/shifts";
import { allPatientTypes } from "@models/patients";
import { templateUnitsSchema, type ITemplate } from "../templateModels";
import { z } from "zod";
import { generateTemplateId } from "../templateUtils";
import { unitSchema } from "@models/units";

export const unitDefaultSingleTemplateKeysSchema = z.enum([
  "admissionVisit",
  "dischargeVisit",
  "bloodSample",
  "replaceAlarm",
  "deliverMaterialBox",
  "retrieveMaterialBox",
  "retrieveTechBox",
  "handoverTreatmentMessage",
  "patientSurvey",
  "returnKey",
  "keyQuittance",
  "checkCatheter",
  "riskAssessment",
  "medicationWalkthrough",
  "replaceMaterialBox",
  "replaceAlarm",
  "round",
  "patientVitalsMorning",
  "fluidMeasurement",
  "patientVitalsEvening",
  "deliverMedication",
  "connectMedicationPump",
  "medicationPumpReplacement",
  "medicationPumpCheck",
  "takeMedicineOnce",
  "takeMedicineTwice",
  "exchangePVK",
  "takeMedicineThreeTimes",
  "ownMeasurementBloodSugar",
  "takeMedicineFourTimes",
  "ownMeasurementWeight",
  "intravenousMedication",
  "prepareDosette",
  "redoCentral",
  "medicationSubcutaneousAdministration",
  "removeMedicationPump",
  "takeMedicineFiveTimes",
  "takeMedicineSixTimes",
  "handoverMedicationList",
]);

export const unitDefaultGroupTemplateKeysSchema = z.enum([
  "admission",
  "discharge",
  "medicationPump",
]);

const _unitDefaultTemplateKeysSchema = z.union([
  unitDefaultSingleTemplateKeysSchema,
  unitDefaultGroupTemplateKeysSchema,
]);

export type IUnitDefaultSingleTemplateKey = z.infer<
  typeof unitDefaultSingleTemplateKeysSchema
>;

export type IUnitDefaultGroupTemplateKey = z.infer<
  typeof unitDefaultGroupTemplateKeysSchema
>;

export type IUnitDefaultTemplateKey = z.infer<
  typeof _unitDefaultTemplateKeysSchema
>;

export const templates: Record<IUnitDefaultSingleTemplateKey, ITemplate> = {
  admissionVisit: {
    title: `Inskrivningsbesök`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.admissionVisit,
    }),
    templateRevision: 1,
  },
  dischargeVisit: {
    title: `Utskrivningsbesök`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.dischargeVisit,
    }),
    templateRevision: 1,
  },
  medicationPumpReplacement: {
    title: `Byte av pump`,
    category: categorySchema.Values.HomeVisit,
    description: `Kom ihåg att ändra tiden till 24 timmar efter att pumpen kopplats och ta sedan bort "dold för patienten"`,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "00:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.medicationPumpReplacement,
    }),
    templateRevision: 1,
  },
  replaceAlarm: {
    title: `Byte larmklocka`,
    category: categorySchema.Values.HomeVisit,
    description: `Fyll i klockans larmnummer under Patientinformation`,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.custom,
    customRecurrenceInterval: "3",
    customRecurrenceIntervalUnit:
      customRecurrenceIntervalUnitSchema.Values.days,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.replaceAlarm,
    }),
    templateRevision: 1,
  },
  replaceMaterialBox: {
    title: `Byte materiallåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.custom,
    customRecurrenceInterval: "5",
    customRecurrenceIntervalUnit:
      customRecurrenceIntervalUnitSchema.Values.days,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.replaceMaterialBox,
    }),
    templateRevision: 1,
  },
  prepareDosette: {
    title: `Dosettdelning`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.prepareDosette,
    }),
    templateRevision: 1,
  },
  ownMeasurementBloodSugar: {
    title: `Egen mätning blodsocker`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "7",
    timeslots: [
      { time: "07:30" },
      { time: "10:00" },
      { time: "11:30" },
      { time: "14:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "23:00" },
    ],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodGlucose"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.ownMeasurementBloodSugar,
    }),
    templateRevision: 1,
  },
  patientVitalsEvening: {
    title: `Egen mätning kväll`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "17:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodPressure", "pulse", "saturation", "temperature"],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.patientVitalsEvening,
    }),
    templateRevision: 1,
  },
  patientVitalsMorning: {
    title: `Egen mätning morgon`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "06:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodPressure", "pulse", "saturation", "temperature"],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.patientVitalsMorning,
    }),
    templateRevision: 1,
  },
  ownMeasurementWeight: {
    title: `Egen mätning vikt`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["weight"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.ownMeasurementWeight,
    }),
    templateRevision: 1,
  },
  retrieveMaterialBox: {
    title: `Hämtning materiallåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.retrieveMaterialBox,
    }),
    templateRevision: 1,
  },
  retrieveTechBox: {
    title: `Hämtning tekniklåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.retrieveTechBox,
    }),
    templateRevision: 1,
  },
  checkCatheter: {
    title: `Kontroll infart`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.checkCatheter,
    }),
    templateRevision: 1,
  },
  connectMedicationPump: {
    title: `Koppla pump`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.connectMedicationPump,
    }),
    templateRevision: 1,
  },
  removeMedicationPump: {
    title: `Koppla bort pump`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.removeMedicationPump,
    }),
    templateRevision: 1,
  },
  deliverMedication: {
    title: `Leverans läkemedel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.onSelectedWeekdays,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.deliverMedication,
    }),
    templateRevision: 1,
  },
  deliverMaterialBox: {
    title: `Leverans materiallåda`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.deliverMaterialBox,
    }),
    templateRevision: 1,
  },
  intravenousMedication: {
    title: `Läkemedel intravenöst`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "3",
    timeslots: [{ time: "00:00" }, { time: "08:00" }, { time: "16:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.intravenousMedication,
    }),
    templateRevision: 1,
  },
  medicationSubcutaneousAdministration: {
    title: `Läkemedel subkutan injektion`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values
          .medicationSubcutaneousAdministration,
    }),
    templateRevision: 1,
  },
  medicationWalkthrough: {
    title: `Läkemedelsgenomgång`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.medicationWalkthrough,
    }),
    templateRevision: 1,
  },
  keyQuittance: {
    title: `Nyckelkvittens`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.keyQuittance,
    }),
    templateRevision: 1,
  },
  redoCentral: {
    title: `Omläggning central infart`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.onSelectedWeekdays,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.redoCentral,
    }),
    templateRevision: 1,
  },
  patientSurvey: {
    title: `Patientenkät`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.patientSurvey,
    }),
    templateRevision: 1,
  },
  exchangePVK: {
    title: `Perfier infart`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.custom,
    customRecurrenceInterval: "3",
    customRecurrenceIntervalUnit:
      customRecurrenceIntervalUnitSchema.Values.days,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.exchangePVK,
    }),
    templateRevision: 1,
  },
  bloodSample: {
    title: `Provtagning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.bloodSample,
    }),
    templateRevision: 1,
  },
  medicationPumpCheck: {
    title: `Pumpkontroll digital`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "18:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.medicationPumpCheck,
    }),
    templateRevision: 1,
  },
  riskAssessment: {
    title: `Riskbedömning`,
    category: categorySchema.Values.HomeVisit,
    description: `För in i Cosmic`,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.riskAssessment,
    }),
    templateRevision: 1,
  },
  round: {
    title: `Rond`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "2",
    requiredCompetences: [
      medicalCompetenceSchema.Values.NursePractitioner,
      medicalCompetenceSchema.Values.MedicalDoctor,
    ],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.round,
    }),
    templateRevision: 1,
  },
  takeMedicineOnce: {
    title: `Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: true,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.takeMedicineOnce,
    }),
    templateRevision: 1,
  },
  takeMedicineTwice: {
    title: `Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "2",
    timeslots: [{ time: "08:00" }, { time: "20:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: true,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.takeMedicineTwice,
    }),
    templateRevision: 1,
  },
  takeMedicineThreeTimes: {
    title: `Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "3",
    timeslots: [{ time: "08:00" }, { time: "14:00" }, { time: "20:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: true,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.takeMedicineThreeTimes,
    }),
    templateRevision: 1,
  },
  takeMedicineFourTimes: {
    title: `Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "4",
    timeslots: [
      { time: "08:00" },
      { time: "14:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: true,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.takeMedicineFourTimes,
    }),
    templateRevision: 1,
  },
  takeMedicineFiveTimes: {
    title: `Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "5",
    timeslots: [
      { time: "08:00" },
      { time: "13:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: true,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.takeMedicineFiveTimes,
    }),
    templateRevision: 1,
  },
  takeMedicineSixTimes: {
    title: `Ta medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "6",
    timeslots: [
      { time: "06:00" },
      { time: "10:00" },
      { time: "14:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "22:00" },
    ],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: true,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.takeMedicineSixTimes,
    }),
    templateRevision: 1,
  },
  fluidMeasurement: {
    title: `Vätske- och urinmätning`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "06:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.fluidMeasurement,
    }),
    templateRevision: 1,
  },
  returnKey: {
    title: `Återlämning nyckel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle: unitDefaultSingleTemplateKeysSchema.Values.returnKey,
    }),
    templateRevision: 1,
  },
  handoverTreatmentMessage: {
    title: `Överlämning behandlingsmeddelande`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.handoverTreatmentMessage,
    }),
    templateRevision: 1,
  },
  handoverMedicationList: {
    title: `Överlämning läkemedelslista`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.Support],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.medoma,
      templateTitle:
        unitDefaultSingleTemplateKeysSchema.Values.handoverMedicationList,
    }),
    templateRevision: 1,
  },
};
