import { Trans, useLingui } from "@lingui/react/macro";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/schedulingModels";
import type { IUnitAlerisAhcTemplate } from "./templatesAlerisAhc";
import { hoursToHoursAndMinutes } from "@/forms/AddActivityForm/Scheduling/timeSlotsUtils";
import type { IWeekdaysPickerFields } from "@/forms/AddActivityForm/Scheduling/WeekdaysPicker";
import { WeekdaysPicker } from "@/forms/AddActivityForm/Scheduling/WeekdaysPicker";
import styles from "./TemplateAlerisAhc.module.scss";
import { Text } from "@components/Text/Text";
import {
  categorySchema,
  type IMeasurementsType,
  timeOfDaySchema,
  videoTypeSchema,
} from "@models/activities";
import type { IDateInputFields } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { DateInput } from "@/forms/AddActivityForm/Scheduling/DateInput";
import type { ITimeSlotsFields } from "@/forms/AddActivityForm/Scheduling/TimeSlots";
import { TimeSlots } from "@/forms/AddActivityForm/Scheduling/TimeSlots";
import { FormProvider, useForm } from "react-hook-form";
import { format } from "@models/date-and-time";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useState } from "react";
import { z } from "zod";
import type {
  INewActivity,
  INewAdminTaskActivity,
  INewHomeVisitActivity,
  INewPatientMeasurementTaskActivity,
  INewPatientTaskActivity,
  INewVideoActivity,
} from "@/api/Activities";
import { ANY_TIME_OF_DAY, activityKeys, addActivity } from "@/api/Activities";
import { deducedError } from "@/Utils/ErrorUtils";
import { generateRandomUUID } from "@/Utils/UniqueId";
import {
  getTimespan,
  getTimeFields,
} from "@/forms/AddActivityForm/activityTimeUtils";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { TemplateHeadingAlerisAhc } from "./TemplateHeadingAlerisAhc";
import { MeasurementsPicker } from "@/forms/AddActivityForm/MeasurementsPicker";
import { usePatient } from "@/api/Patients";
import { saveActivityInvoicingCodes, shouldSaveInvoice } from "@/api/Invoicing";
import { invoicingCodeTypeOptionsSchema } from "@models/invoicing";
import { Heading } from "@components/Heading/Heading";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";

type ITemplateWithDate = IDateInputFields & {
  measurements: IMeasurementsType[];
};
type ITemplateWithTimeSlotsAndDate = ITimeSlotsFields &
  IDateInputFields & { measurements: IMeasurementsType[] };
type ITemplateWithWeekdaysAndDate = IWeekdaysPickerFields &
  IDateInputFields & { measurements: IMeasurementsType[] };
type ITemplateFormFields =
  | ITemplateWithDate
  | ITemplateWithTimeSlotsAndDate
  | ITemplateWithWeekdaysAndDate;

const TemplateAlerisAhc = ({
  template,
}: {
  template: IUnitAlerisAhcTemplate;
}) => {
  const { t } = useLingui();

  const hasTimeSlots = "timeslots" in template;
  const showWeekdaysInput =
    template.recurrence === recurringOptionSchema.Values.onSelectedWeekdays;
  const isRecurring =
    template.frequency === activityFrequencySchema.Values.recurring;
  const isPatientMeasurementTask =
    template.category === categorySchema.Values.PatientMeasurementTask &&
    template.measurements.length === 0;

  const methods = useForm<ITemplateFormFields>({
    defaultValues: {
      startDate: format(new Date(), "yyyy-MM-dd"),
      timeslots: hasTimeSlots ? template.timeslots : undefined,
      weekdays: template.weekdays,
      measurements: isPatientMeasurementTask
        ? undefined
        : template.measurements,
    },
  });

  const queryClient = useQueryClient();
  const { patientId } = z
    .object({ patientId: z.string().uuid() })
    .parse(useParams());
  const { data: patient } = usePatient(patientId);
  const [templateIsExpanded, setTemplateIsExpanded] = useState(false);

  const { data: invoicingActivated } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.PERMANENT_ShowInvoicingInUI,
  );

  const {
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setError,
    getValues,
  } = methods;

  const { mutate, isPending: isAdding } = useMutation({
    mutationFn: (newActivity: INewActivity) =>
      addActivity(newActivity).then(() =>
        shouldSaveInvoice({
          invoicingActivated: invoicingActivated ?? false,
          category: newActivity.category,
          procedureCodes: procedureCodeIds,
          productCodes: productCodeIds,
        })
          ? saveActivityInvoicingCodes({
              activityId: newActivity.activityId,
              patientId,
              procedureCodes: procedureCodeIds,
              productCodes: productCodeIds,
            })
          : null,
      ),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: activityKeys.all });
      setTemplateIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((validatedFormData) => {
    // 1st, get the values from the template object.
    const {
      title,
      category,
      description,
      duration,
      doubleStaffing,
      frequency,
      recurrence,
      customRecurrenceInterval,
      customRecurrenceIntervalUnit,
      requiredCompetences,
      timeCategory,
      hidden,
      templateId,
      templateRevision,
    } = template;
    const timeSensitivity =
      "timeSensitivity" in template ? template.timeSensitivity : undefined;

    // 2nd, get the values from the form, which are the final values
    // If the form has a value, use that, otherwise use the template value.
    const { startDate, endDate } = validatedFormData;
    const timeslots =
      "timeslots" in validatedFormData
        ? validatedFormData.timeslots
        : "timeslots" in template
          ? template.timeslots
          : [];
    const weekdays =
      "weekdays" in validatedFormData
        ? validatedFormData.weekdays
        : template.weekdays;

    const measurements =
      "measurements" in validatedFormData
        ? validatedFormData.measurements
        : template.measurements;

    const isAnyTimeOfDay = timeCategory === timeOfDaySchema.Values.Any;
    const isRecurring =
      frequency === activityFrequencySchema.Values.recurring &&
      recurrence !== null;
    const isRecurringAsInterval =
      recurrence !== recurringOptionSchema.Values.onSelectedWeekdays;

    const commonActivityFields: Pick<
      INewActivity,
      | "activityId"
      | "category"
      | "description"
      | "duration"
      | "hidden"
      | "startDate"
      | "timespan"
      | "title"
    > = {
      activityId: generateRandomUUID(),
      category,
      description,
      duration: duration ?? 15,
      hidden,
      startDate,
      timespan: getTimespan({
        isAnyTimeOfDay,
        timeSensitivity,
      }),
      title,
    };

    const timeFields = isRecurring
      ? {
          ...getTimeFields({
            isRecurringAsInterval,
            isAnyTimeOfDay,
            timeslots,
            weekdays,
            recurrence,
            customRecurrenceInterval,
            customRecurrenceIntervalUnit,
          }),
          endDate: endDate === "" ? undefined : endDate,
        }
      : // @ts-expect-error we know that there is exactly one timeslot, but TS doesn't.
        { time: isAnyTimeOfDay ? ANY_TIME_OF_DAY : timeslots[0].time }; // We only have one timeslot if it's not recurring.

    if (category === categorySchema.Values.VideoCall) {
      const newActivity: INewVideoActivity = {
        ...commonActivityFields,
        patientId,
        category: categorySchema.Values.VideoCall,
        requiredCompetences,
        ...timeFields,
        type: videoTypeSchema.Values.DigitalVisit,
        templateId,
        templateRevision,
      };

      mutate(newActivity);
    }

    if (category === categorySchema.Values.HomeVisit) {
      const newActivity: INewHomeVisitActivity = {
        ...commonActivityFields,
        patientId,
        category: categorySchema.Values.HomeVisit,
        requiredCompetences,
        ...timeFields,
        doubleStaffing,
        templateId,
        templateRevision,
      };

      mutate(newActivity);
    }

    if (category === categorySchema.Values.PatientTask) {
      const newActivity: INewPatientTaskActivity = {
        ...commonActivityFields,
        patientId,
        category: categorySchema.Values.PatientTask,
        ...timeFields,
        templateId,
        templateRevision,
      };

      mutate(newActivity);
    }

    if (category === categorySchema.Values.AdminTask) {
      const newActivity: INewAdminTaskActivity = {
        ...commonActivityFields,
        patientId: patientId ? patientId : null, // Represent lack of patient id as null.
        category: categorySchema.Values.AdminTask,
        requiredCompetences: requiredCompetences ? requiredCompetences : [],
        ...timeFields,
        templateId,
        templateRevision,
      };

      mutate(newActivity);
    }

    if (category === categorySchema.Values.PatientMeasurementTask) {
      const newActivity: INewPatientMeasurementTaskActivity = {
        ...commonActivityFields,
        patientId,
        category: categorySchema.Values.PatientMeasurementTask,
        measurements,
        ...timeFields,
        templateId,
        templateRevision,
      };

      mutate(newActivity);
    }
  });

  const timeSensitivityValue =
    "timeSensitivity" in template
      ? hoursToHoursAndMinutes(template.timeSensitivity)
      : undefined;

  const invoicingCodes =
    "invoicingCodes" in template ? template.invoicingCodes : null;
  const invoicingCodesForPatientType = invoicingCodes?.filter(
    (invoicingCode) =>
      patient && patient.patientType === invoicingCode.patientType,
  );
  const allProductCodes = invoicingCodesForPatientType?.filter(
    (invoicingCode) =>
      invoicingCode.type === invoicingCodeTypeOptionsSchema.Values.productCode,
  );
  const allProcedureCodes = invoicingCodesForPatientType?.filter(
    (invoicingCode) =>
      invoicingCode.type ===
      invoicingCodeTypeOptionsSchema.Values.procedureCode,
  );
  const productCodeIds =
    allProductCodes?.map((productCode) => productCode.id) ?? [];
  const procedureCodeIds =
    allProcedureCodes?.map((procedureCode) => procedureCode.id) ?? [];

  return (
    <ExpansionPanel
      trigger={
        <TemplateHeadingAlerisAhc
          template={template}
          variant={templateIsExpanded ? "title-and-info" : "title-only"}
        />
      }
      triggerAriaLabel={template.title}
      isExpanded={templateIsExpanded}
      setIsExpanded={setTemplateIsExpanded}
    >
      <FormProvider {...methods}>
        <Form onSubmit={validateAndSubmit}>
          <div className={styles.form}>
            {invoicingActivated &&
            invoicingCodesForPatientType &&
            invoicingCodesForPatientType.length > 0 ? (
              <div>
                <Heading
                  level="h3"
                  margin="bottom"
                >{t`Faktureringskoder`}</Heading>
                {allProcedureCodes && allProcedureCodes.length > 0 ? (
                  <Text element="p">
                    <Trans>Åtgärd (KVÅ)</Trans>:
                    {allProcedureCodes
                      .map(
                        (procedureCode) =>
                          ` ${procedureCode.code} - ${procedureCode.name}`,
                      )
                      .join(", ")}
                  </Text>
                ) : null}
                {allProductCodes && allProductCodes.length > 0 ? (
                  <Text element="p">
                    <Trans>Produkt:</Trans>
                    {allProductCodes
                      .map(
                        (productCode) =>
                          ` ${productCode.code} - ${productCode.name}`,
                      )
                      .join(", ")}
                  </Text>
                ) : null}
              </div>
            ) : null}
            {isPatientMeasurementTask ? <MeasurementsPicker /> : null}
            {showWeekdaysInput ? <WeekdaysPicker /> : null}
            {hasTimeSlots ? (
              <div className={styles.times}>
                <TimeSlots recurrences={Number(template.recurrencesPerDay)} />
                <Text element="p" color="faded">
                  <Trans>(Ska genomföras inom {timeSensitivityValue})</Trans>
                </Text>
              </div>
            ) : null}
            <DateInput hasEndDate={isRecurring} />
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}
            <FilledButton type="submit" disabled={isAdding}>
              <Trans>Lägg till aktivitet</Trans>
            </FilledButton>
          </div>
        </Form>
      </FormProvider>
    </ExpansionPanel>
  );
};

export { TemplateAlerisAhc as Template };
