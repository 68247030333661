import { shiftTimeDisplayValues, type IScheduledShift } from "@models/shifts";
import { formattedTime } from "@/components/Time/timeUtils";
import { Text, type IText } from "@components/Text/Text";
import { useLingui } from "@lingui/react/macro";
import styles from "./ShiftTime.module.scss";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import clsx from "clsx";

type IShiftTime = Pick<IScheduledShift, "startDateTime" | "endDateTime"> &
  Pick<IText, "size" | "color"> & {
    layout?: "horizontal" | "vertical";
  };

const ShiftTime = ({
  startDateTime,
  endDateTime,
  size,
  color,
  layout = "vertical",
}: IShiftTime) => {
  const { t } = useLingui();
  const selectedDate = useSelectedDate();
  const selectedDateAsDate = new Date(selectedDate);

  const {
    startedDayBefore,
    hasEndTimeAfterToday,
    formattedDayBefore,
    formattedDayToday,
  } = shiftTimeDisplayValues({
    selectedDate: selectedDateAsDate,
    startDateTime,
    endDateTime,
  });

  const additionalContext = `${startedDayBefore ? t`Start ${formattedDayBefore}` : hasEndTimeAfterToday ? t`Start ${formattedDayToday}` : ""}`;
  const hasAdditionalContext = additionalContext !== "";

  return (
    <>
      <span className={clsx([styles.shiftTime, styles[`layout-${layout}`]])}>
        {hasAdditionalContext ? (
          <Text
            element="span"
            color="faded"
            size={layout === "vertical" ? "small" : size}
          >
            {additionalContext}
          </Text>
        ) : null}
        <Text element="span" size={size} color={color}>
          {formattedTime(startDateTime)} - {formattedTime(endDateTime)}
        </Text>
      </span>
    </>
  );
};

export { ShiftTime };
