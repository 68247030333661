/* eslint-disable lingui/no-unlocalized-strings */
// Don't translate user-generated content
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/schedulingModels";
import { categorySchema, timeOfDaySchema } from "@models/activities";
import { medicalCompetenceSchema } from "@models/shifts";
import { allPatientTypes, patientTypeSchema } from "@models/patients";
import { templateUnitsSchema, type ITemplate } from "../templateModels";
import { z } from "zod";
import { generateTemplateId } from "../templateUtils";

export const unitSivNsvtSingleTemplateKeysSchema = z.enum([
  "admissionVisit",
  "dischargeAdmin",
  "heartVisit",
  "otherVisit",
  "patientVitals",
  "ownMeasurementBloodSugar",
  "handoverMedicationList",
  "bloodSample",
  "medicationManagement",
  "socialFollowUp",
  "swollenLegs",
  "lungs",
  "medicationReview",
  "referral",
  "finalNote",
  "updateMedicationList",
  "informationToPatient",
  "vitalParameters",
  "nutritionAndFluids",
  "dehydration",
  "bloodSampleOneTime",
]);

export const unitSivNsvtGroupTemplateKeysSchema = z.enum([
  "admission",
  "discharge",
  "heartVisit",
  "otherVisit",
]);

const _unitSivNsvtTemplateKeysSchema = z.union([
  unitSivNsvtSingleTemplateKeysSchema,
  unitSivNsvtGroupTemplateKeysSchema,
]);

export type IUnitSivNsvtSingleTemplateKey = z.infer<
  typeof unitSivNsvtSingleTemplateKeysSchema
>;

export type IUnitSivNsvtGroupTemplateKey = z.infer<
  typeof unitSivNsvtGroupTemplateKeysSchema
>;

export type IUnitSivNsvtTemplateKey = z.infer<
  typeof _unitSivNsvtTemplateKeysSchema
>;

export const templates: Record<IUnitSivNsvtSingleTemplateKey, ITemplate> = {
  admissionVisit: {
    title: `Nybesök`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "6",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.admissionVisit,
    }),
    templateRevision: 1,
  },
  dischargeAdmin: {
    title: `Utskrivning Admin`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.dischargeAdmin,
    }),
    templateRevision: 1,
  },
  heartVisit: {
    title: `Hjärtbesök`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "6",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.heartVisit,
    }),
    templateRevision: 1,
  },
  otherVisit: {
    title: `Övrigt besök`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "6",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.otherVisit,
    }),
    templateRevision: 1,
  },
  patientVitals: {
    title: `Egen mätning`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.onSelectedWeekdays,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.patientVitals,
    }),
    templateRevision: 1,
  },
  ownMeasurementBloodSugar: {
    title: `Egen mätning blodsocker`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "7",
    timeslots: [
      { time: "07:30" },
      { time: "10:00" },
      { time: "11:30" },
      { time: "14:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "23:00" },
    ],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodGlucose"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle:
        unitSivNsvtSingleTemplateKeysSchema.Values.ownMeasurementBloodSugar,
    }),
    templateRevision: 1,
  },
  bloodSample: {
    title: `Provtagning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    recurrence: recurringOptionSchema.Values.onSelectedWeekdays,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    frequency: activityFrequencySchema.Values.recurring,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "--:--" }], // The user must choose a time
    recurrencesPerDay: "1",
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.bloodSample,
    }),
    templateRevision: 1,
  },
  bloodSampleOneTime: {
    title: `Provtagning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    frequency: activityFrequencySchema.Values.oneTime,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "6",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle:
        unitSivNsvtSingleTemplateKeysSchema.Values.bloodSampleOneTime,
    }),
    templateRevision: 1,
  },
  handoverMedicationList: {
    title: `Lämna läkemedelslista`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle:
        unitSivNsvtSingleTemplateKeysSchema.Values.handoverMedicationList,
    }),
    templateRevision: 1,
  },
  medicationManagement: {
    title: `Läkemedelshantering`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle:
        unitSivNsvtSingleTemplateKeysSchema.Values.medicationManagement,
    }),
    templateRevision: 1,
  },
  socialFollowUp: {
    title: `Social uppföljning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.socialFollowUp,
    }),
    templateRevision: 1,
  },
  swollenLegs: {
    title: `Bensvullnad`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.swollenLegs,
    }),
    templateRevision: 1,
  },
  lungs: {
    title: `Lungor`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.lungs,
    }),
    templateRevision: 1,
  },
  medicationReview: {
    title: `Läkemedelsgenomgång`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle:
        unitSivNsvtSingleTemplateKeysSchema.Values.medicationReview,
    }),
    templateRevision: 1,
  },
  referral: {
    title: `Remiss`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.referral,
    }),
    templateRevision: 1,
  },
  finalNote: {
    title: `Slutanteckning`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.finalNote,
    }),
    templateRevision: 1,
  },
  updateMedicationList: {
    title: `Uppdatera läkemedelslista/apodos`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle:
        unitSivNsvtSingleTemplateKeysSchema.Values.updateMedicationList,
    }),
    templateRevision: 1,
  },
  informationToPatient: {
    title: `Information till patient`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle:
        unitSivNsvtSingleTemplateKeysSchema.Values.informationToPatient,
    }),
    templateRevision: 1,
  },
  vitalParameters: {
    title: `Vitalparametrar`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.vitalParameters,
    }),
    templateRevision: 1,
  },
  nutritionAndFluids: {
    title: `Kost och vätskegenomgång`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle:
        unitSivNsvtSingleTemplateKeysSchema.Values.nutritionAndFluids,
    }),
    templateRevision: 1,
  },
  dehydration: {
    title: `Urvätskning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.sivnsvt,
      templateTitle: unitSivNsvtSingleTemplateKeysSchema.Values.dehydration,
    }),
    templateRevision: 1,
  },
};
