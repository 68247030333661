/* eslint-disable lingui/no-unlocalized-strings */
// Don't translate user-generated content
import {
  activityFrequencySchema,
  customRecurrenceIntervalUnitSchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/schedulingModels";
import {
  categorySchema,
  measurementSchema,
  timeOfDaySchema,
} from "@models/activities";
import { medicalCompetenceSchema } from "@models/shifts";
import { allPatientTypes, patientTypeSchema } from "@models/patients";
import type { IInvoicingCode } from "@models/invoicing";
import {
  templateUnitsSchema,
  type IAnyTimeOfDayTemplate,
  type IBaseTemplate,
} from "../templateModels";
import { z } from "zod";
import { generateTemplateId } from "../templateUtils";

type IInvoicingCodeTemplate = IBaseTemplate & {
  invoicingCodes: IInvoicingCode[];
};

// Must be a subset of IAddActivityFormData
export type IUnitAlerisAhcTemplate =
  | IBaseTemplate
  | IAnyTimeOfDayTemplate
  | IInvoicingCodeTemplate;

export const unitAlerisAhcSingleTemplateKeysSchema = z.enum([
  "sskAdmissionVisit",
  "medicalDoctorAdmissionVisit",
  "dischargeVisit",
  "medicalProcedures",
  "returnKey",
  "keyQuittance",
  "checkCatheter",
  "riskAssessment",
  "patientVitals",
  "connectMedicationPump",
  "medicationPumpReplacement",
  "medicationPumpCheck",
  "intravenousMedication",
  "handoverMedicationList",
  "securityReceipt",
  "teamRound",
  "medicationReviewSimple",
  "medicationReviewAdvanced",
  "bloodTransfusion",
  "retrievalLoanedIVStand",
  "medication",
  "admissionInterview",
  "workEnvironmentRiskAssessment",
  "leaveInformationMap",
  "updatePatientInformation",
  "removeMedicationPump",
  "symptomAssessment",
  "centralLine",
  "catheter",
  "stoma",
  "woundDressingChange",
  "drain",
  "nephrostomy",
  "injection",
  "infusionIn",
  "infusionOut",
  "monitorLabResults",
  "orderBlood",
  "monitorAdmission",
  "orderEDosRoll",
  "bereavementConversation",
  "prescription",
  "conferenceWithPatient",
  "exchangePVK",
  "leaveMedication",
  "bloodSample",
  "screening",
  "evaluateAndEndActivityPlans",
  "bloodSugarOnce",
  "bloodSugarTwice",
  "bloodSugerThreeTimes",
  "bloodTransfusionDoctor",
  "bloodTransfusionHematologist",
  "bloodTransfusionOncologist",
  "supportToCaregivers",
  "IVMedicationsSabo",
  "bloodTransfusionSabo",
  "painReducingTreatmentSabo",
  "nutritionalTreatmentSabo",
  "drainageMaintenanceSabo",
  "palliativeCareSabo",
  "brachytherapySabo",
  "teachDigitalSystems",
  "bringEmergencyKit",
  "handOutEDos",
  "documentRiskAssessmentTc",
  "mrsaScreening",
  "handOutDosette",
  "intravenousAntibiotica",
]);

export const unitAlerisAhcGroupTemplateKeysSchema = z.enum([
  "admission",
  "discharge",
  "medicalProcedures",
  "medicationPump",
]);

const _unitAlerisAhcTemplateKeysSchema = z.union([
  unitAlerisAhcSingleTemplateKeysSchema,
  unitAlerisAhcGroupTemplateKeysSchema,
]);

export type IUnitAlerisAhcSingleTemplateKey = z.infer<
  typeof unitAlerisAhcSingleTemplateKeysSchema
>;

export type IUnitAlerisAhcGroupTemplateKey = z.infer<
  typeof unitAlerisAhcGroupTemplateKeysSchema
>;

export type IUnitAlerisAhcTemplateKey = z.infer<
  typeof _unitAlerisAhcTemplateKeysSchema
>;

export const templates: Record<
  IUnitAlerisAhcSingleTemplateKey,
  IUnitAlerisAhcTemplate
> = {
  sskAdmissionVisit: {
    title: `Inskrivningsbesök SSK`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.sskAdmissionVisit,
    }),
    templateRevision: 1,
  },
  medicalDoctorAdmissionVisit: {
    title: `Inskrivningsbesök läkare`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values
          .medicalDoctorAdmissionVisit,
    }),
    templateRevision: 1,
  },
  dischargeVisit: {
    title: `Utskrivningsbesök`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.dischargeVisit,
    }),
    templateRevision: 1,
  },
  intravenousAntibiotica: {
    title: `Antibiotika intravenöst`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "3",
    timeslots: [{ time: "01:00" }, { time: "09:00" }, { time: "13:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.intravenousAntibiotica,
    }),
    templateRevision: 1,
  },
  medicalProcedures: {
    title: `Medicinska åtgärder`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.medicalProcedures,
    }),
    templateRevision: 1,
  },
  mrsaScreening: {
    title: `MRSA-screening`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.mrsaScreening,
    }),
    templateRevision: 1,
  },
  orderBlood: {
    title: `Beställ blod`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.orderBlood,
    }),
    templateRevision: 1,
  },
  orderEDosRoll: {
    title: `Beställ eDos-rulle`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.onSelectedWeekdays,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "07:30" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: ["Tuesday", "Friday"],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.orderEDosRoll,
    }),
    templateRevision: 1,
  },
  monitorAdmission: {
    title: `Bevaka inläggning`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.monitorAdmission,
    }),
    templateRevision: 1,
  },
  monitorLabResults: {
    title: `Bevaka prover`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.monitorLabResults,
    }),
    templateRevision: 1,
  },
  bloodTransfusion: {
    title: `Blodtransfusion`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    frequency: activityFrequencySchema.Values.oneTime,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.bloodTransfusion,
    }),
    templateRevision: 1,
  },
  medicationPumpReplacement: {
    title: `Byte av kassett`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "00:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.medicationPumpReplacement,
    }),
    templateRevision: 1,
  },
  bereavementConversation: {
    title: `Efterlevandesamtal`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.bereavementConversation,
    }),
    templateRevision: 1,
  },
  patientVitals: {
    title: `Egen mätning`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.patientVitals,
    }),
    templateRevision: 1,
  },
  bloodSugarOnce: {
    title: `Egen mätning 1 tillfälle`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [measurementSchema.Values.bloodGlucose],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.bloodSugarOnce,
    }),
    templateRevision: 1,
  },
  bloodSugarTwice: {
    title: `Egen mätning 2 tillfällen`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "2",
    timeslots: [{ time: "08:00" }, { time: "20:00" }],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [measurementSchema.Values.bloodGlucose],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.bloodSugarTwice,
    }),
    templateRevision: 1,
  },
  bloodSugerThreeTimes: {
    title: `Egen mätning 3 tillfällen`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "3",
    timeslots: [{ time: "08:00" }, { time: "14:00" }, { time: "20:00" }],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [measurementSchema.Values.bloodGlucose],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.bloodSugerThreeTimes,
    }),
    templateRevision: 1,
  },
  prescription: {
    title: `Förskrivning`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.prescription,
    }),
    templateRevision: 1,
  },
  checkCatheter: {
    title: `Kontroll medicinska accesser och dränage`,
    category: categorySchema.Values.HomeVisit,
    description: `Centrala infarter, kateter, stomi, såromläggning, drän, nefrostomi`,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.checkCatheter,
    }),
    templateRevision: 1,
  },
  connectMedicationPump: {
    title: `Koppla pump`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.connectMedicationPump,
    }),
    templateRevision: 1,
  },
  conferenceWithPatient: {
    title: `Konferens med patient`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.conferenceWithPatient,
    }),
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "8fd8fd35-46a3-4334-95ee-db17a4474aa3",
        name: "Konferens med patient",
        code: "XS007",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  removeMedicationPump: {
    title: `Koppla bort pump`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.removeMedicationPump,
    }),
    templateRevision: 1,
  },
  intravenousMedication: {
    title: `Läkemedel intravenöst`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeSensitivity: "1",
    recurrencesPerDay: "2",
    timeslots: [{ time: "--:--" }, { time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.intravenousMedication,
    }),
    templateRevision: 1,
  },
  medicationReviewSimple: {
    title: `Läkemedelsgenomgång, enkel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.medicationReviewSimple,
    }),
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "64cfeac5-ff19-4fdf-b97d-57bcaf737a8f",
        name: "Läkemedelsgenomgång, enkel",
        code: "XV015",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  medicationReviewAdvanced: {
    title: `Läkemedelsgenomgång, fördjupad`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.medicationReviewAdvanced,
    }),
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "10d44b1e-2198-4585-b099-c245aad0e884",
        name: "Läkemedelsgenomgång, fördjupad",
        code: "XV016",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  keyQuittance: {
    title: `Nyckelkvittens`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.keyQuittance,
    }),
    templateRevision: 1,
  },
  bloodSample: {
    title: `Provtagning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.bloodSample,
    }),
    templateRevision: 1,
  },
  medicationPumpCheck: {
    title: `Pumpkontroll digital`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.medicationPumpCheck,
    }),
    templateRevision: 1,
  },
  riskAssessment: {
    title: `Riskbedömningar`,
    category: categorySchema.Values.HomeVisit,
    description: `ROAG, MNA, Downton och Norton`,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.riskAssessment,
    }),
    templateRevision: 1,
  },
  teamRound: {
    title: `Teamkonferens`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.custom,
    customRecurrenceInterval: "8",
    customRecurrenceIntervalUnit:
      customRecurrenceIntervalUnitSchema.Values.weeks,
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    recurrencesPerDay: "1",
    timeslots: [{ time: "14:00" }],
    //TODO: should be 0 time sensitivity
    timeSensitivity: "0.25",
    doubleStaffing: false,
    duration: 60,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.teamRound,
    }),
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "41351101-db8a-4966-8fd5-3503eda99ee6",
        name: "Konferens om patient",
        code: "XS008",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  returnKey: {
    title: `Återlämning nyckel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.returnKey,
    }),
    templateRevision: 1,
  },
  handoverMedicationList: {
    title: `Lämna aktuell läkemedelslista`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.handoverMedicationList,
    }),
    templateRevision: 1,
  },
  securityReceipt: {
    title: `Trygghetskvitto`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.securityReceipt,
    }),
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "01b05847-4f2a-4584-b7c4-09df69f2d1e7",
        name: "Trygghetskvitto",
        code: "UV017",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  retrievalLoanedIVStand: {
    title: `Hämtning av utlånad droppställning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.retrievalLoanedIVStand,
    }),
    templateRevision: 1,
  },
  medication: {
    title: `Läkemedel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.medication,
    }),
    templateRevision: 1,
  },
  admissionInterview: {
    title: `Inskrivningssamtal`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.admissionInterview,
    }),
    templateRevision: 1,
  },
  workEnvironmentRiskAssessment: {
    title: `Arbetsmiljö riskbedömning`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values
          .workEnvironmentRiskAssessment,
    }),
    templateRevision: 1,
  },
  leaveInformationMap: {
    title: `Lämna informationsmapp`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.leaveInformationMap,
    }),
    templateRevision: 1,
  },
  updatePatientInformation: {
    title: `Uppdatera kontaktuppgifter`,
    category: categorySchema.Values.AdminTask,
    description: `-Uppdatera ASIH-telefonnr i TakeCare\n-Uppdatera telefonnr patient och anhörig`,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.updatePatientInformation,
    }),
    templateRevision: 1,
  },
  documentRiskAssessmentTc: {
    title: `Dokumentera riskbedömning i TakeCare`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.documentRiskAssessmentTc,
    }),
    templateRevision: 1,
  },
  symptomAssessment: {
    title: `Symtomkontroll`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.symptomAssessment,
    }),
    templateRevision: 1,
  },
  centralLine: {
    title: `Omläggning centrala infarter`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.centralLine,
    }),
    templateRevision: 1,
  },
  catheter: {
    title: `Kateter`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.catheter,
    }),
    templateRevision: 1,
  },
  stoma: {
    title: `Stomi`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.stoma,
    }),
    templateRevision: 1,
  },
  woundDressingChange: {
    title: `Såromläggning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.woundDressingChange,
    }),
    templateRevision: 1,
  },
  drain: {
    title: `Drän`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.drain,
    }),
    templateRevision: 1,
  },
  nephrostomy: {
    title: `Nefrostomi`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.nephrostomy,
    }),
    templateRevision: 1,
  },
  injection: {
    title: `Injektion`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.injection,
    }),
    templateRevision: 1,
  },
  infusionIn: {
    title: `Infusion in`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.infusionIn,
    }),
    templateRevision: 1,
  },
  infusionOut: {
    title: `Infusion ut`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    recurrencesPerDay: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.infusionOut,
    }),
    templateRevision: 1,
  },
  exchangePVK: {
    title: `Perifer infart`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.exchangePVK,
    }),
    templateRevision: 1,
  },
  leaveMedication: {
    title: `Lämna läkemedel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.leaveMedication,
    }),
    templateRevision: 1,
  },
  screening: {
    title: `Screening`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.screening,
    }),
    templateRevision: 1,
  },
  handOutDosette: {
    title: `Utlämnande dosett`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.handOutEDos,
    }),
    templateRevision: 1,
  },
  handOutEDos: {
    title: `Utlämnande E-dos`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle: unitAlerisAhcSingleTemplateKeysSchema.Values.handOutEDos,
    }),
    templateRevision: 1,
  },
  evaluateAndEndActivityPlans: {
    title: `Utvärdera och avsluta aktivitetsplaner`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values
          .evaluateAndEndActivityPlans,
    }),
    templateRevision: 1,
  },

  teachDigitalSystems: {
    title: `Undervisa digitala system`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.teachDigitalSystems,
    }),
    templateRevision: 1,
  },
  bringEmergencyKit: {
    title: `Ta med akutkit`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.bringEmergencyKit,
    }),
    templateRevision: 1,
  },
  bloodTransfusionDoctor: {
    title: `Blodtransfusion på remiss från husläkare`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "6c64470e-27e1-4d6d-9c97-95002a4bc5ee",
        name: "Uppdrag blodtransfusion på remiss från husläkare",
        code: "UW014",
        type: "procedureCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.bloodTransfusionDoctor,
    }),
    templateRevision: 1,
  },
  bloodTransfusionHematologist: {
    title: `Blodtransfusion på remiss från hematolog`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "eca95eaa-71f4-4a3f-a713-3ae629b96ebc",
        name: "Uppdrag blodtransfusion på remiss från hematolog eller onkolog",
        code: "29116",
        type: "productCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values
          .bloodTransfusionHematologist,
    }),
    templateRevision: 1,
  },
  bloodTransfusionOncologist: {
    title: `Blodtransfusion på remiss från onkolog`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "eca95eaa-71f4-4a3f-a713-3ae629b96ebc",
        name: "Uppdrag blodtransfusion på remiss från hematolog eller onkolog",
        code: "29116",
        type: "productCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.bloodTransfusionOncologist,
    }),
    templateRevision: 1,
  },
  supportToCaregivers: {
    title: `Stöd till andra vårdgivare`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "163c6b97-568f-44e3-93c5-b6158525ba93",
        name: "Patientrelaterad extern information och utbildning",
        code: "GB004",
        type: "procedureCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.supportToCaregivers,
    }),
    templateRevision: 1,
  },
  IVMedicationsSabo: {
    title: `Intravenöst Läkemedel på SÄBO`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "a5488ef4-cf9c-4283-b2e0-57fb68edbc72",
        name: "Uppdrag på SÄBO ska kompletteras med KVÅ",
        code: "29114",
        type: "productCode",
        patientType: "External",
      },
      {
        id: "b4966539-7df4-41d0-9b08-a5158bd8263c",
        name: "Läkemedelstillförsel, intravenös",
        code: "DT016",
        type: "procedureCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.IVMedicationsSabo,
    }),
    templateRevision: 1,
  },
  bloodTransfusionSabo: {
    title: `Blodtransfusion på SÄBO`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "a5488ef4-cf9c-4283-b2e0-57fb68edbc72",
        name: "Uppdrag på SÄBO ska kompletteras med KVÅ",
        code: "29114",
        type: "productCode",
        patientType: "External",
      },
      {
        id: "a68c7026-215b-4ca8-87d1-310f0ed32302",
        name: "Transfusion, helblod, allogen",
        code: "DR033",
        type: "procedureCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.bloodTransfusionSabo,
    }),
    templateRevision: 1,
  },
  painReducingTreatmentSabo: {
    title: `Smärtreducerande behandling på SÄBO`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "a5488ef4-cf9c-4283-b2e0-57fb68edbc72",
        name: "Uppdrag på SÄBO ska kompletteras med KVÅ",
        code: "29114",
        type: "productCode",
        patientType: "External",
      },
      {
        id: "e11fab12-412f-4102-b648-44d8967842f3",
        name: "Smärtreducerande behandling",
        code: "QB008",
        type: "procedureCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.painReducingTreatmentSabo,
    }),
    templateRevision: 1,
  },
  nutritionalTreatmentSabo: {
    title: `Nutritionsbehandling på SÄBO`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "a5488ef4-cf9c-4283-b2e0-57fb68edbc72",
        name: "Uppdrag på SÄBO ska kompletteras med KVÅ",
        code: "29114",
        type: "productCode",
        patientType: "External",
      },
      {
        id: "095ecc05-0533-48ef-a578-775016944aec",
        name: "Nutritionsbehandling, parenteral näringstillförsel",
        code: "DV055",
        type: "procedureCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.nutritionalTreatmentSabo,
    }),
    templateRevision: 1,
  },
  drainageMaintenanceSabo: {
    title: `Skötsel av dränage på SÄBO`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "a5488ef4-cf9c-4283-b2e0-57fb68edbc72",
        name: "Uppdrag på SÄBO ska kompletteras med KVÅ",
        code: "29114",
        type: "productCode",
        patientType: "External",
      },
      {
        id: "efd8a11a-6028-494a-9a07-050e98a0e629",
        name: "Skötsel av dränage",
        code: "DV150",
        type: "procedureCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.drainageMaintenanceSabo,
    }),
    templateRevision: 1,
  },
  palliativeCareSabo: {
    title: `Palliativ vård på SÄBO`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 60,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "a5488ef4-cf9c-4283-b2e0-57fb68edbc72",
        name: "Uppdrag på SÄBO ska kompletteras med KVÅ",
        code: "29114",
        type: "productCode",
        patientType: "External",
      },
      {
        id: "8743941f-0184-46db-87a0-031351be3a5c",
        name: "Vård i livets slutskede",
        code: "QX006",
        type: "procedureCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.palliativeCareSabo,
    }),
    templateRevision: 1,
  },
  brachytherapySabo: {
    title: `Brakyterapi på SÄBO`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "13",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 60,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.External],
      showNumberOfOccurrencesInHeader: false,
    },
    invoicingCodes: [
      {
        id: "a5488ef4-cf9c-4283-b2e0-57fb68edbc72",
        name: "Uppdrag på SÄBO ska kompletteras med KVÅ",
        code: "29114",
        type: "productCode",
        patientType: "External",
      },
      {
        id: "a12136ee-3201-4442-a747-889f5d92ad6c",
        name: "Brakyterapi, interstitiell lågdosrat (temporärt implantat)",
        code: "DV014",
        type: "procedureCode",
        patientType: "External",
      },
    ],
    templateId: await generateTemplateId({
      unit: templateUnitsSchema.Values.alerisahc,
      templateTitle:
        unitAlerisAhcSingleTemplateKeysSchema.Values.brachytherapySabo,
    }),
    templateRevision: 1,
  },
};
