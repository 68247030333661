import { msg } from "@lingui/core/macro";
/* eslint-disable @typescript-eslint/no-unused-vars */
// We have a pattern to use types based on zod values in these files.

import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import {
  fetchPatient,
  fetchPatients,
  createDeletedPatient,
  type IListPatient,
  resolvePatient,
  patientKeys,
} from "./Patients";
import { logisticsApi } from "./ApiClient";
import type { IMedicalCompetence } from "@models/shifts";
import { medicalCompetences, medicalCompetenceSchema } from "@models/shifts";
import { generateQueryString } from "./Helpers";
import { deduplicatePrimitiveArray } from "@/Utils/arrayUtils";
import { dateSchema, format } from "@models/date-and-time";
import type {
  IActivityCategory,
  IActivityOccurrenceOrGroup,
  IActivityOccurrenceOrGroupWithPatientId,
  IActivityOccurrenceStatus,
  IMeasurementsType,
} from "@models/activities";
import {
  activityOccurrenceOrGroupSchema,
  activityOccurrenceOrGroupWithPatientSchema,
  categorySchema,
  homeVisitActivityOccurrenceSchema,
  videoTypeSchema,
  measurementSchema,
  activityOccurrencesAndGroupsWithPatientIdsSchema,
  activityOccurrenceWithPatientIdSchema,
  adminTaskOccurrenceSchema,
  videoActivityOccurrenceSchema,
  isGroup,
  groupOfHomeVisitActivityOccurrenceWithPatientIdSchema,
  groupOfHomeVisitActivityOccurrenceSchema,
  activityOccurrenceWithPatientSchema,
} from "@models/activities";
import { startOfToday } from "date-fns";
import { type IPatient } from "@models/patients";

export const activityCategoryDictionary = {
  HomeVisit: msg`Hembesök`,
  VideoCall: msg`Videosamtal`,
  PatientTask: msg`Patientaktivitet`,
  AdminTask: msg`Administrativ uppgift`,
  PatientMeasurementTask: msg`Mätvärden`,
};

const activityRequirements = [...medicalCompetences, "DoubleStaffing"] as const;
export const activityRequirementSchema = z.enum(activityRequirements);
export type IActivityRequirement = z.infer<typeof activityRequirementSchema>;

const requiredTime = z.string();
export type IRequiredTime = z.infer<typeof requiredTime>;

export const parseAndEnrichActivityOccurrencesAndGroups = async (
  probableActivityOccurrencesAndGroups: unknown,
) => {
  const parsedActivityOccurrencesAndGroups =
    activityOccurrencesAndGroupsWithPatientIdsSchema.parse(
      probableActivityOccurrencesAndGroups,
    );

  if (parsedActivityOccurrencesAndGroups.length === 0) {
    return parsedActivityOccurrencesAndGroups as [];
  }

  const patientIds = deduplicatePrimitiveArray(
    // AdminTask activities sometimes miss patientId
    parsedActivityOccurrencesAndGroups
      .map(({ patientId }) => patientId)
      .filter(Boolean) as string[],
  );
  const patients = await fetchPatients({ patientIds });

  const enrichedActivityOccurrencesAndGroups =
    parsedActivityOccurrencesAndGroups.map((activityOccurrenceOrGroup) => {
      const patient = resolvePatient({
        patientId: activityOccurrenceOrGroup.patientId,
        patients,
      });

      if (isGroup(activityOccurrenceOrGroup)) {
        const group = activityOccurrenceOrGroup;
        return {
          ...group,
          patient,
          occurrences: group.occurrences.map((occurrence) => {
            return {
              ...occurrence,
              patient,
            };
          }),
        };
      } else {
        const activityOccurrence = activityOccurrenceOrGroup;
        return {
          ...activityOccurrence,
          patient,
        };
      }
    });

  return z
    .array(activityOccurrenceOrGroupSchema)
    .parse(enrichedActivityOccurrencesAndGroups);
};

export async function fetchActivityOccurrencesAndGroups(
  from?: string,
  to?: string,
) {
  const queryString = generateQueryString({ from, to });
  const activityOccurrencesAndGroupsResponse = await logisticsApi.get(
    `/occurrences${queryString}`,
  );
  return await parseAndEnrichActivityOccurrencesAndGroups(
    activityOccurrencesAndGroupsResponse.data,
  );
}

export async function fetchHomeVisitActivityOccurrences(
  from?: string,
  to?: string,
) {
  const queryString = generateQueryString({ from, to });
  const activityOccurrencesAndGroupsResponse = await logisticsApi.get(
    `/occurrences${queryString}`,
  );
  const activityOccurrencesAndGroups =
    await parseAndEnrichActivityOccurrencesAndGroups(
      activityOccurrencesAndGroupsResponse.data,
    );

  const homeVisitOccurrencesAndGroups = activityOccurrencesAndGroups.filter(
    ({ category }) => category === categorySchema.Values.HomeVisit,
  );

  const homeVisitOccurrences = homeVisitOccurrencesAndGroups.flatMap(
    (occurrenceOrGroup) => {
      if (isGroup(occurrenceOrGroup)) {
        return occurrenceOrGroup.occurrences;
      } else {
        return occurrenceOrGroup;
      }
    },
  );

  return z.array(homeVisitActivityOccurrenceSchema).parse(homeVisitOccurrences);
}

const parseAndEnrichActivityOccurrenceOrGroup = ({
  activityOccurrenceOrGroup,
  patient,
}: {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroupWithPatientId;
  patient: IPatient;
}) => {
  const enrichedActivityOccurrence = {
    ...activityOccurrenceOrGroup,
    patient,
  };
  return activityOccurrenceWithPatientSchema.parse(enrichedActivityOccurrence);
};

export async function fetchActivityOccurrence(
  activityId: string,
  occurrenceId: string,
) {
  const activityOccurrenceResponse = await logisticsApi.get(
    `/activities/${activityId}/occurrences/${occurrenceId}`,
  );
  const parsedActivityOccurrence = activityOccurrenceWithPatientIdSchema.parse(
    activityOccurrenceResponse.data,
  );

  const patientId = parsedActivityOccurrence.patientId;
  if (!patientId) return parsedActivityOccurrence;

  try {
    const patient = await fetchPatient(patientId);
    return parseAndEnrichActivityOccurrenceOrGroup({
      activityOccurrenceOrGroup: parsedActivityOccurrence,
      patient,
    });
  } catch (e) {
    const patient = createDeletedPatient(patientId);
    return parseAndEnrichActivityOccurrenceOrGroup({
      activityOccurrenceOrGroup: parsedActivityOccurrence,
      patient,
    });
  }
}

export async function fetchHomeVisitGroup(id: string) {
  const groupResponse = await logisticsApi.get(`/occurrence-groups/${id}`);
  const preparsedGroup = await parseAndEnrichActivityOccurrencesAndGroups([
    groupResponse.data,
  ]);
  const parsedGroup = groupOfHomeVisitActivityOccurrenceSchema.parse(
    preparsedGroup[0],
  );

  return parsedGroup;
}

export const activityKeys = {
  all: ["activities"] as const,
  lists: () => [...activityKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...activityKeys.lists(), filters] as const,
  detail: (id: string) => [...activityKeys.all, id, "details"] as const,
};

export const activityOccurrenceAndGroupKeys = {
  all: () => [...activityKeys.all, "occurrences"] as const,
  lists: () => [...activityOccurrenceAndGroupKeys.all(), "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...activityOccurrenceAndGroupKeys.lists(), filters] as const,
  detail: (id: string) =>
    [...activityOccurrenceAndGroupKeys.all(), id, "details"] as const,
};

const ONE_MINUTE = 1000 * 60;

export const useActivityOccurrencesAndGroups = (from: string, to: string) => {
  return useQuery({
    queryKey: activityOccurrenceAndGroupKeys.list({ from, to }),
    queryFn: () => fetchActivityOccurrencesAndGroups(from, to),
    // Activity occurrences have time based status logic, so we need to refetch them often where status matters/is shown.
    refetchInterval: ONE_MINUTE,
    throwOnError: true,
  });
};

export const useActivityOccurrence = (
  activityId: string,
  occurrenceId: string,
) => {
  return useQuery({
    queryKey: activityOccurrenceAndGroupKeys.detail(
      `${activityId}${occurrenceId}`,
    ),
    queryFn: () => fetchActivityOccurrence(activityId, occurrenceId),
  });
};

export const useHomeVisitGroup = (id: string) => {
  return useQuery({
    queryKey: activityOccurrenceAndGroupKeys.detail(`${id}`),
    queryFn: () => fetchHomeVisitGroup(id),
  });
};

async function fetchPatientsActivityOccurrencesAndGroups(
  patientId: string,
  from?: string,
  to?: string,
) {
  const patientsActivityOccurrencesAndGroupsResponse = await logisticsApi.get(
    `patients/${patientId}/occurrences${generateQueryString({
      from,
      to,
      status: ["active", "closed"],
    })}`,
  );
  const parsedAndEnrichedActivityOccurrencesAndGroups = z
    .array(activityOccurrenceOrGroupWithPatientSchema)
    .parse(
      await parseAndEnrichActivityOccurrencesAndGroups(
        patientsActivityOccurrencesAndGroupsResponse.data,
      ),
    );
  return parsedAndEnrichedActivityOccurrencesAndGroups;
}

export const usePatientsActivityOccurrencesAndGroups = (
  patientId: string,
  from?: string,
  to?: string,
) => {
  return useQuery({
    queryKey: activityOccurrenceAndGroupKeys.list({ patientId, from, to }),
    queryFn: () =>
      fetchPatientsActivityOccurrencesAndGroups(patientId, from, to),
    // Activity occurrences have time based status logic, so we need to refetch them often where status matters/is shown.
    refetchInterval: ONE_MINUTE,
    // Avoid flashing when fetching more history: https://tanstack.com/query/latest/docs/framework/react/guides/paginated-queries#better-paginated-queries-with-placeholderdata
    placeholderData: keepPreviousData,
  });
};

export const useHomeVisitActivityOccurrences = (from: string, to: string) => {
  return useQuery({
    queryKey: activityOccurrenceAndGroupKeys.list({
      from,
      to,
      category: categorySchema.Values.HomeVisit,
    }),
    queryFn: () => fetchHomeVisitActivityOccurrences(from, to),
    staleTime: 1000 * 60 * 10,
  });
};

export async function fetchActivity(activityId: string) {
  const activityResponse = await logisticsApi.get(`/activities/${activityId}`);
  const parsedActivity = activitySchema.parse(activityResponse.data);
  return parsedActivity;
}

export const useActivity = (activityId: string) => {
  return useQuery({
    queryKey: activityKeys.detail(activityId),
    queryFn: () => {
      return fetchActivity(activityId);
    },
  });
};

export const closeActivity = async (activityId: string) => {
  await logisticsApi.delete(`/activities/${activityId}`);
};

export const removeActivityOccurrence = async (
  activityId: string,
  occurrenceId: string,
) => {
  await logisticsApi.delete(
    `/activities/${activityId}/occurrences/${occurrenceId}`,
  );
};

export const allocateShiftsToVisit = async (
  shiftIds: number[],
  routeId: string,
  visitId: string,
) => {
  await logisticsApi.post(`/routes/${routeId}/visits/${visitId}/allocate`, {
    shifts: shiftIds,
  });
};

export const deallocateShiftsFromVisit = async (
  shiftIds: number[],
  routeId: string,
  visitId: string,
) => {
  await logisticsApi.post(`/routes/${routeId}/visits/${visitId}/deallocate`, {
    shifts: shiftIds,
  });
};

export const allocateShiftsToActivityOccurrence = async (
  shiftIds: number[],
  activityId: string,
  occurrenceId: string,
) => {
  await logisticsApi.post(
    `/activities/${activityId}/occurrences/${occurrenceId}/allocate`,
    {
      shifts: shiftIds,
    },
  );
};

export const deallocateShiftsFromActivityOccurrence = async (
  shiftIds: number[],
  activityId: string,
  occurrenceId: string,
) => {
  await logisticsApi.post(
    `/activities/${activityId}/occurrences/${occurrenceId}/deallocate`,
    {
      shifts: shiftIds,
    },
  );
};

export const finishActivityOccurrence = async (
  activityId: string,
  occurrenceId: string,
) => {
  await logisticsApi.post(
    `activities/${activityId}/occurrences/${occurrenceId}/finish`,
  );
};

export const resetActivityOccurrence = async (
  activityId: string,
  occurrenceId: string,
) => {
  await logisticsApi.post(
    `activities/${activityId}/occurrences/${occurrenceId}/reset`,
  );
};
export const ANY_TIME_OF_DAY = "AnyTimeOfDay" as const;
const anyTimeOfDaySchema = z.literal(ANY_TIME_OF_DAY);

const timeSchema = z.union([z.string(), anyTimeOfDaySchema]);
const timesSchema = z.union([z.array(z.string()), anyTimeOfDaySchema]);
export type ITime = z.infer<typeof timeSchema>;
export type ITimes = z.infer<typeof timesSchema>;

export const weekdaySchema = z.enum([
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]);
export type IWeekday = z.infer<typeof weekdaySchema>;

const intervalSchema = z.number().int().positive();
export type IInterval = z.infer<typeof intervalSchema>;

const frequencyBaseSchema = z.object({
  start: dateSchema,
  end: dateSchema.nullish(),
  span: z.string(),
  times: timesSchema,
});

const weekdayFrequencySchema = frequencyBaseSchema.extend({
  days: z.array(weekdaySchema),
});

const intervalFrequencySchema = frequencyBaseSchema.extend({
  interval: intervalSchema,
});

const frequencySchema = z.union([
  weekdayFrequencySchema,
  intervalFrequencySchema,
]);

const oneTimeSchema = z.object({
  date: z.string(),
  time: z.union([z.string(), anyTimeOfDaySchema]),
  span: z.string(),
});

const activityScheduleSchema = z.union([oneTimeSchema, frequencySchema]);
export type IActivitySchedule = z.infer<typeof activityScheduleSchema>;

const rescheduleActivitySchema = z.union([
  weekdayFrequencySchema.omit({ start: true, end: true }),
  intervalFrequencySchema.omit({ start: true, end: true }),
]);
export type IRescheduleActivity = z.infer<typeof rescheduleActivitySchema>;

export const activityStatusSchema = z.enum(["active", "closed"]);

const activityBaseSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  description: z.string().nullish(),
  schedule: activityScheduleSchema,
  recurring: z.boolean(),
  category: categorySchema,
  duration: z.number().int(),
  status: activityStatusSchema,
  hidden: z.boolean(),
});

const newActivityOmit = {
  id: true,
  recurring: true,
  schedule: true,
  status: true,
} as const;

const newActivityExtend = {
  activityId: z.string().uuid(),
  timespan: z.string(),
  startDate: z.string(),
  templateId: z.string().uuid().optional(),
  templateRevision: z.number().int().optional(),
};

const customRecurrenceExtend = {
  days: z.array(weekdaySchema),
  times: timesSchema,
  endDate: z.string().optional(),
};

const preconfiguredRecurrenceExtend = {
  interval: intervalSchema,
  times: timesSchema,
  endDate: z.string().optional(),
};

const oneTimeExtend = {
  time: timeSchema,
};

// HOME VISIT
const activityHomeVisitSchema = activityBaseSchema.extend({
  category: z.literal(categorySchema.Values.HomeVisit),
  doubleStaffing: z.boolean(),
  requiredCompetences: z.array(medicalCompetenceSchema),
  patientId: z.string().uuid(),
});

const newHomeVisitActivityBaseSchema = activityHomeVisitSchema
  .omit(newActivityOmit)
  .extend(newActivityExtend);

const newOneTimeHomeVisitActivitySchema =
  newHomeVisitActivityBaseSchema.extend(oneTimeExtend);

const newRecurringHomeVisitActivitySchema = z.union([
  newHomeVisitActivityBaseSchema.extend(customRecurrenceExtend),
  newHomeVisitActivityBaseSchema.extend(preconfiguredRecurrenceExtend),
]);

const newHomeVisitActivitySchema = z.union([
  newOneTimeHomeVisitActivitySchema,
  newRecurringHomeVisitActivitySchema,
]);
export type INewHomeVisitActivity = z.infer<typeof newHomeVisitActivitySchema>;

// VIDEO
const activityVideoSchema = activityBaseSchema.extend({
  category: z.literal(categorySchema.Values.VideoCall),
  requiredCompetences: z.array(medicalCompetenceSchema),
  type: videoTypeSchema,
  patientId: z.string().uuid(),
});
const newVideoActivityBaseSchema = activityVideoSchema
  .omit(newActivityOmit)
  .extend(newActivityExtend);

const newOneTimeVideoActivitySchema =
  newVideoActivityBaseSchema.extend(oneTimeExtend);

const newRecurringVideoActivitySchema = z.union([
  newVideoActivityBaseSchema.extend(customRecurrenceExtend),
  newVideoActivityBaseSchema.extend(preconfiguredRecurrenceExtend),
]);

const newVideoActivitySchema = z.union([
  newOneTimeVideoActivitySchema,
  newRecurringVideoActivitySchema,
]);
export type INewVideoActivity = z.infer<typeof newVideoActivitySchema>;

// PATIENT
const activityPatientTaskSchema = activityBaseSchema.extend({
  category: z.literal(categorySchema.Values.PatientTask),
  patientId: z.string().uuid(),
});

const newPatientTaskActivityBaseSchema = activityPatientTaskSchema
  .omit(newActivityOmit)
  .extend(newActivityExtend);

const newOneTimePatientTaskActivitySchema =
  newPatientTaskActivityBaseSchema.extend(oneTimeExtend);

const newRecurringPatientTaskActivitySchema = z.union([
  newPatientTaskActivityBaseSchema.extend(customRecurrenceExtend),
  newPatientTaskActivityBaseSchema.extend(preconfiguredRecurrenceExtend),
]);

const newPatientTaskActivitySchema = z.union([
  newOneTimePatientTaskActivitySchema,
  newRecurringPatientTaskActivitySchema,
]);
export type INewPatientTaskActivity = z.infer<
  typeof newPatientTaskActivitySchema
>;

// ADMIN
const activityAdminTaskSchema = activityBaseSchema.extend({
  category: z.literal(categorySchema.Values.AdminTask),
  patientId: z.string().uuid().nullish(),
  requiredCompetences: z.array(medicalCompetenceSchema),
});

const newAdminTaskActivityBaseSchema = activityAdminTaskSchema
  .omit(newActivityOmit)
  .extend(newActivityExtend);

const newOneTimeAdminTaskActivitySchema =
  newAdminTaskActivityBaseSchema.extend(oneTimeExtend);

const newRecurringAdminTaskActivitySchema = z.union([
  newAdminTaskActivityBaseSchema.extend(customRecurrenceExtend),
  newAdminTaskActivityBaseSchema.extend(preconfiguredRecurrenceExtend),
]);

const newAdminTaskActivitySchema = z.union([
  newOneTimeAdminTaskActivitySchema,
  newRecurringAdminTaskActivitySchema,
]);
export type INewAdminTaskActivity = z.infer<typeof newAdminTaskActivitySchema>;

//  PATIENT MEASUREMENTS
const activityPatientMeasurementTaskSchema = activityBaseSchema.extend({
  category: z.literal(categorySchema.Values.PatientMeasurementTask),
  patientId: z.string().uuid(),
  measurements: z.array(measurementSchema),
});

const newPatientMeasurementTaskActivityBaseSchema =
  activityPatientMeasurementTaskSchema
    .omit(newActivityOmit)
    .extend(newActivityExtend);

const newOneTimePatientMeasurementTaskActivitySchema =
  newPatientMeasurementTaskActivityBaseSchema.extend(oneTimeExtend);

const newRecurringPatientMeasurementTaskActivitySchema = z.union([
  newPatientMeasurementTaskActivityBaseSchema.extend(customRecurrenceExtend),
  newPatientMeasurementTaskActivityBaseSchema.extend(
    preconfiguredRecurrenceExtend,
  ),
]);

const newPatientMeasurementTaskActivitySchema = z.union([
  newOneTimePatientMeasurementTaskActivitySchema,
  newRecurringPatientMeasurementTaskActivitySchema,
]);
export type INewPatientMeasurementTaskActivity = z.infer<
  typeof newPatientMeasurementTaskActivitySchema
>;

const newActivitySchema = z.union([
  newHomeVisitActivitySchema,
  newVideoActivitySchema,
  newPatientTaskActivitySchema,
  newAdminTaskActivitySchema,
  newPatientMeasurementTaskActivitySchema,
]);
export type INewActivity = z.infer<typeof newActivitySchema>;

export async function addActivity(newActivity: INewActivity) {
  await logisticsApi.post("/activities", newActivity);
}
export async function addActivities(newActivities: INewActivity[]) {
  await logisticsApi.post("/activities/bulk", { activities: newActivities });
}
export async function addActivitiesFromGroupTemplate({
  templateId,
  templateRevision,
  newActivities,
}: {
  templateId: string;
  templateRevision: number;
  newActivities: INewActivity[];
}) {
  await logisticsApi.post("/activities/bulk", {
    templateId,
    templateRevision,
    activities: newActivities,
  });
}

const activitySchema = z.union([
  activityHomeVisitSchema,
  activityVideoSchema,
  activityPatientTaskSchema,
  activityAdminTaskSchema,
  activityPatientMeasurementTaskSchema,
]);

export type IActivity = z.infer<typeof activitySchema>;

const newActivityInVisitSchema = newHomeVisitActivityBaseSchema
  .pick({
    activityId: true,
    title: true,
    requiredCompetences: true,
    description: true,
  })
  .extend({
    visitId: z.string(),
    routeId: z.string().uuid(),
  });

export type INewActivityInVisit = z.infer<typeof newActivityInVisitSchema>;

export const addActivityInVisit = async (
  newActivityInVisit: INewActivityInVisit,
) => {
  await logisticsApi.post("/activities/contextual", newActivityInVisit);
};

export type INewJitActivityInVisit = z.infer<
  typeof newJitActivityInVisitSchema
>;

const newJitActivityInVisitSchema = newHomeVisitActivityBaseSchema
  .pick({
    activityId: true,
    title: true,
    description: true,
  })
  .extend({
    visitId: z.string(),
    routeId: z.string().uuid(),
  });

const newActivityInGroupSchema = newHomeVisitActivityBaseSchema
  .pick({
    activityId: true,
    title: true,
    requiredCompetences: true,
  })
  .extend({
    groupId: z.string(),
  });

export type INewActivityInGroup = z.infer<typeof newActivityInGroupSchema>;

export const addActivityInGroup = async (
  newActivityInGroup: INewActivityInGroup,
) => {
  await logisticsApi.post("/activities/contextual", newActivityInGroup);
};

export const updateTitle = async (activityId: string, title: string) => {
  const patch = {
    op: title ? "replace" : "remove",
    path: "/title",
    value: title,
  };
  await logisticsApi.patch(`/activities/${activityId}`, [patch]);
};

export const updateDescription = async (
  activityId: string,
  description: string,
) => {
  const patch = {
    op: description ? "replace" : "remove",
    path: "/description",
    value: description,
  };
  await logisticsApi.patch(`/activities/${activityId}`, [patch]);
};

export const updateHidden = async (activityId: string, hidden: boolean) => {
  const patch = {
    op: "replace",
    path: "/hidden",
    value: hidden,
  };
  await logisticsApi.patch(`/activities/${activityId}`, [patch]);
};

export const updateRequiredCompetences = async (
  activityId: string,
  requiredCompetences: IMedicalCompetence[],
) => {
  const patch = {
    op: "replace",
    path: "/competence",
    value: requiredCompetences,
  };
  await logisticsApi.patch(`/activities/${activityId}`, [patch]);
};

export const updateMeasurements = async (
  activityId: string,
  measurements: IMeasurementsType[],
) => {
  const patch = {
    op: "replace",
    path: "/measurements",
    value: measurements,
  };
  await logisticsApi.patch(`/activities/${activityId}`, [patch]);
};

export const updateDoubleStaffing = async (
  activityId: string,
  doubleStaffing: boolean,
) => {
  const patch = {
    op: "replace",
    path: "/doubleStaffing",
    value: doubleStaffing,
  };
  await logisticsApi.patch(`/activities/${activityId}`, [patch]);
};

export const updateEndDate = async (activityId: string, endDateTime: Date) => {
  const patch = {
    op: "replace",
    path: "/endDate",
    value: endDateTime,
  };
  await logisticsApi.patch(`/activities/${activityId}`, [patch]);
};

export const rescheduleActivity = async (
  activityId: string,
  data: IRescheduleActivity,
) => {
  await logisticsApi.post(`/activities/${activityId}/reschedule`, data);
};

export type IRescheduledActivityOccurrence = {
  date: string;
  time: ITime;
  span?: string;
};
export const rescheduleActivityOccurrence = async (
  activityId: string,
  occurrenceId: string,
  data: IRescheduledActivityOccurrence,
) => {
  await logisticsApi.post(
    `/activities/${activityId}/occurrences/${occurrenceId}/reschedule`,
    data,
  );
};

const fetchEligibleGroups = async ({
  activityId,
  occurrenceId,
}: {
  activityId: string;
  occurrenceId: string;
}) => {
  const eligibleGroupsResponse = await logisticsApi.get(
    `/activities/${activityId}/occurrences/${occurrenceId}/eligible-occurrence-groups`,
  );

  const eligibleGroups = z
    .array(groupOfHomeVisitActivityOccurrenceWithPatientIdSchema)
    .parse(eligibleGroupsResponse.data);

  return eligibleGroups;
};

export const eligibleGroupsKeys = {
  all: ["eligibleGroups"] as const,
  detail: ({
    activityId,
    occurrenceId,
  }: {
    activityId: string;
    occurrenceId: string;
  }) => [...eligibleGroupsKeys.all, activityId, occurrenceId] as const,
};
export const useEligibleGroups = ({
  activityId,
  occurrenceId,
}: {
  activityId: string;
  occurrenceId: string;
}) => {
  return useQuery({
    queryKey: eligibleGroupsKeys.detail({ activityId, occurrenceId }),
    queryFn: () => fetchEligibleGroups({ activityId, occurrenceId }),
  });
};

export const changeGroup = async (
  activityId: string,
  occurrenceId: string,
  groupId: string,
) => {
  await logisticsApi.post(
    `/activities/${activityId}/occurrences/${occurrenceId}/move`,
    {
      targetGroupId: groupId,
    },
  );
};

export const useUnplannedOccurrencesAndGroups = (from: string, to: string) => {
  return useQuery({
    queryKey: activityOccurrenceAndGroupKeys.list({
      from,
      to,
      unplanned: true,
    }),
    queryFn: () => fetchUnplannedOccurrencesAndGroups(from, to),
    // Activity occurrences have time based status logic, so we need to refetch them often where status matters/is shown.
    refetchInterval: ONE_MINUTE,
    throwOnError: true,
  });
};

export async function fetchUnplannedOccurrencesAndGroups(
  from?: string,
  to?: string,
) {
  const queryString = generateQueryString({ from, to });
  const occurrencesAndGroupsResponse = await logisticsApi.get(
    `/occurrences/unplanned${queryString}`,
  );

  return await parseAndEnrichActivityOccurrencesAndGroups(
    occurrencesAndGroupsResponse.data,
  );
}
