import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import {
  useSelectedDate,
  useSelectedDateHasPassed,
} from "@/Utils/useSelectedDate";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Heading } from "@components/Heading/Heading";
import NoResults from "@/components/NoResults/NoResults";
import * as Sentry from "@sentry/react";
import { deducedError } from "@/Utils/ErrorUtils";
import {
  getUniqueId,
  timeOfDaySchema,
  type IActivityCategory,
  type IActivityOccurrenceOrGroup,
  type ITimeOfDay,
} from "@models/activities";
import { useUnplannedOccurrencesAndGroups } from "@/api/Activities";
import {
  useDragAndDrop,
  type DraggableCollectionStartEvent,
  type Key,
  type Selection,
} from "react-aria-components";
import styles from "./PlanningSideBar.module.scss";
import { MedomaGridList } from "@/components/DragAndDrop/DragAndDrop";
import { PlainButton } from "@components/Button/Button";
import PlusIcon from "@components/icons/PlusIcon";
import { useContext, useLayoutEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getGroupedRowModel,
  useReactTable,
  type GroupingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { getDataFromActivityOccurrencesAndGroups } from "../../Activities/ActivitiesTable/getDataFromActivityOccurrences";
import { ControlledSelect } from "@/components/Select/Select";
import { GroupedRows } from "@/pages/commandcenter/Planning/SideBar/GroupedRows";
import {
  type IMedicalCompetence,
  medicalCompetenceDictionary,
} from "@models/shifts";
import { timeOfDay } from "@/api/Routes";
import { useLingui } from "@lingui/react";
import { PlanningContext } from "../PlanningContext";
import { useMap } from "react-map-gl/mapbox";
import { patientStatusSchema } from "@models/patients";
import { useNavigate } from "react-router";
import type { I18n } from "@lingui/core";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import { CategoryIcon } from "@/components/CategoryIcon/CategoryIcon";
import { Text } from "@components/Text/Text";

type IActivityTableItem = {
  activityOccurrenceOrGroup: IActivityOccurrenceOrGroup;
  category: IActivityOccurrenceOrGroup["category"];
  patient: IActivityOccurrenceOrGroup["patient"];
  requiredCompetences: IMedicalCompetence[] | undefined;
  timespan: [Date, Date, ITimeOfDay];
  area: IActivityOccurrenceOrGroup["patient"];
};

const columnHelper = createColumnHelper<IActivityTableItem>();

const GROUPABLE_COLUMNS = [
  "category",
  "patient",
  "requiredCompetences",
  "timespan",
  "area",
] as const;

const COLUMN_DISPLAY_NAMES = {
  category: msg`Aktivitetstyp`,
  patient: msg`Patient`,
  requiredCompetences: msg`Kompetens`,
  timespan: msg`Tid`,
  area: msg`Område`,
};

const NO_COMPETENCE = msg`Inget kompetensbehov`;
const SEVERAL_COMPETENCES = msg`Flera kompetenser`;

const columns = (translator: I18n["_"]) => [
  columnHelper.accessor("category", {
    header: translator(COLUMN_DISPLAY_NAMES["category"]),
    cell: ({ getValue }) => {
      return getValue();
    },
  }),
  columnHelper.accessor("timespan", {
    header: translator(COLUMN_DISPLAY_NAMES["timespan"]),
    getGroupingValue: ({ timespan }) => {
      const [start, _, type] = timespan;
      return timeOfDay(
        type === timeOfDaySchema.Values.Any
          ? timeOfDaySchema.Values.Any
          : start,
      );
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<[Date, Date, ITimeOfDay]>("timespan");
      const bState = b.getValue<[Date, Date, ITimeOfDay]>("timespan");

      // Grouped rows are unlikely to have a timespan
      // If we don't have a timespan, we can't sort by it
      if (!aState || !bState) return 0;

      // Any time of day -> end of list
      if (aState[2] === "Any") {
        return 1;
      }
      // Any time of day -> end of list
      if (bState[2] === "Any") {
        return 1;
      }
      //sort by start time
      if (aState[0] < bState[0]) {
        return -1;
      } else if (aState[0] > bState[0]) {
        return 1;
      }
      return 0;
    },
  }),
  columnHelper.accessor("requiredCompetences", {
    header: translator(COLUMN_DISPLAY_NAMES["requiredCompetences"]),
    getGroupingValue: ({ requiredCompetences }) => {
      if (!requiredCompetences || !requiredCompetences[0]) {
        return translator(NO_COMPETENCE);
      }
      if (requiredCompetences.length > 1) {
        return translator(SEVERAL_COMPETENCES);
      }
      return translator(
        medicalCompetenceDictionary[requiredCompetences[0]].short,
      );
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<IMedicalCompetence[] | undefined>(
        "requiredCompetences",
      );
      const bState = b.getValue<IMedicalCompetence[] | undefined>(
        "requiredCompetences",
      );

      if (!aState) {
        return -1;
      } else if (!bState) {
        return 1;
      }

      // Sort activities with several competences to the bottom
      if (aState.length > 1) {
        return 1;
      } else if (bState.length > 1) {
        return -1;
      }

      return 0;
    },
  }),
  columnHelper.accessor("patient", {
    header: translator(COLUMN_DISPLAY_NAMES["patient"]),
    getGroupingValue: ({ patient }) => {
      return patient ? patient.id : undefined;
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<
        IActivityOccurrenceOrGroup["patient"] | undefined
      >("patient");
      const bState = b.getValue<
        IActivityOccurrenceOrGroup["patient"] | undefined
      >("patient");

      // Sort patient-less activities to the top
      if (!aState) {
        return -1;
      } else if (!bState) {
        return 1;
      }

      // Sort activities with deleted patients to the bottom
      if (aState.status === patientStatusSchema.Values.deleted) {
        return 1;
      } else if (bState.status === patientStatusSchema.Values.deleted) {
        return -1;
      }

      if (aState.name < bState.name) {
        return -1;
      } else if (aState.name > bState.name) {
        return 1;
      } else if (aState.id < bState.id) {
        return -1;
      } else if (aState.id > bState.id) {
        return 1;
      }
      return 0;
    },
  }),
  columnHelper.accessor("area", {
    header: translator(COLUMN_DISPLAY_NAMES["area"]),
    getGroupingValue: ({ patient }) => {
      if (!patient) return `-`;
      if (patient.status === patientStatusSchema.Values.deleted) return `-`;
      if (!patient.area) return `-`;
      return patient.area.displayName;
    },
    sortingFn: (a, b) => {
      const aState = a.getValue<
        IActivityOccurrenceOrGroup["patient"] | undefined
      >("patient");
      const bState = b.getValue<
        IActivityOccurrenceOrGroup["patient"] | undefined
      >("patient");

      // Sort activities with deleted patients to the bottom
      if (aState?.status === patientStatusSchema.Values.deleted) {
        return 1;
      } else if (bState?.status === patientStatusSchema.Values.deleted) {
        return -1;
      }

      // Sort activities with no area to the bottom
      if (!aState?.area) {
        return -1;
      } else if (!bState?.area) {
        return 1;
      }

      // Sort by area alphabetically
      if (aState.area) {
        return -1;
      } else if (bState.area) {
        return 1;
      }

      // Sort patient-less activities to the bottom
      if (!aState) {
        return 1;
      }
      if (!bState) {
        return -1;
      }

      return 0;
    },
  }),
];

export const PlanningSideBar = ({
  isPendingAddToShift,
}: {
  isPendingAddToShift: boolean;
}) => {
  const { _ } = useLingui();
  const NO_GROUPING = _(msg`Ingen gruppering`);
  const selectedDate = new Date(useSelectedDate());
  const hasDatePassed = useSelectedDateHasPassed();

  const navigate = useNavigate();
  const {
    selectedItem,
    setSelectedItem,
    setDraggedItem,
    boundsShowingAllPatients,
  } = useContext(PlanningContext);

  const { planningMap } = useMap();

  const {
    data: unplannedOccurrencesAndGroups,
    isPending,
    isError,
    error,
  } = useUnplannedOccurrencesAndGroups(
    selectedDate.toDateString(),
    selectedDate.toDateString(),
  );

  useLayoutEffect(() => {
    if (!planningMap) {
      // planningMap is probably closed, or just not loaded, so this is a no-op
      return;
    } else {
      planningMap.resize();
    }
  }, [unplannedOccurrencesAndGroups, planningMap]);

  const handleDragStart = (e: DraggableCollectionStartEvent) => {
    const draggedKeys = e.keys;

    const firstItem = getOccurrenceOrGroupByFirstKey(draggedKeys);

    setDraggedItem(firstItem);
    setSelectedItem(undefined);
  };

  const getOccurrenceOrGroupByFirstKey = (keys: Selection) => {
    if (!keys) return undefined;
    if (keys === "all") return undefined;

    const firstKey = keys.values().next().value;

    return unplannedOccurrencesAndGroups?.find(
      (item) => getUniqueId(item) === firstKey,
    );
  };

  const handleDragEnd = () => {
    setDraggedItem(undefined);
  };

  const { dragAndDropHooks } = useDragAndDrop({
    isDisabled: hasDatePassed,
    getItems: (keys) => {
      return [...keys].map((key) => {
        const item = unplannedOccurrencesAndGroups?.find(
          (occurrenceOrGroup) => getUniqueId(occurrenceOrGroup) === key,
        );

        if (!item) {
          return {
            "medoma-item": "",
            "patient-name": "",
            category: "",
          };
        }

        if (
          !item.patient ||
          item.patient.status === patientStatusSchema.Values.deleted
        ) {
          return {
            "medoma-item": getUniqueId(item),
            "patient-name": "",
            category: item.category,
          };
        }

        return {
          "medoma-item": getUniqueId(item),
          "patient-name": item.patient.name,
          category: item.category,
        };
      });
    },
    onDragStart: handleDragStart,
    onDragEnd: handleDragEnd,
    renderDragPreview(items) {
      const itemBeingDragged = items?.[0];
      if (!itemBeingDragged) {
        return <></>;
      }
      const draggedName = itemBeingDragged["patient-name"];
      const draggedCategory = itemBeingDragged.category as IActivityCategory;
      return (
        <div className={styles.dragPreview}>
          <CategoryIcon category={draggedCategory} size="small" />
          <Text element="p">{draggedName}</Text>
        </div>
      );
    },
  });

  const [grouping, setGrouping] = useState<GroupingState>(["category"]);

  const sorting = useMemo(() => {
    return [...grouping.map((group) => ({ id: group, desc: false }))];
  }, [grouping]);

  const { data: areaEnabled } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.Area,
  );

  const data = useMemo(
    () =>
      getDataFromActivityOccurrencesAndGroups(
        unplannedOccurrencesAndGroups || [],
      ),
    [unplannedOccurrencesAndGroups],
  );
  const translatedColumns = columns(_);
  const table = useReactTable({
    data,
    columns: translatedColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    groupedColumnMode: false, // Don't move grouped columns to the left
    state: {
      grouping,
      sorting,
    },
  });

  const getPatientCoordinates = (newSelectedKeys: Selection) => {
    const matchedItem = unplannedOccurrencesAndGroups?.find(
      (unplannedOccurrenceOrGroup) => {
        return (
          newSelectedKeys !== "all" &&
          newSelectedKeys.has(getUniqueId(unplannedOccurrenceOrGroup))
        );
      },
    );

    if (
      !matchedItem ||
      !matchedItem.patient ||
      matchedItem.patient.status === patientStatusSchema.Values.deleted
    )
      return null;

    const { longitude, latitude } = matchedItem.patient.address.coordinates;
    if (longitude && latitude) {
      return { longitude, latitude };
    }
    return null;
  };

  const easeToPatientCoordinates = (newSelectedKeys: Selection) => {
    if (newSelectedKeys === "all" || newSelectedKeys?.size === 0) {
      return;
    }

    const patientCoordinates = getPatientCoordinates(newSelectedKeys);

    if (patientCoordinates) {
      planningMap?.easeTo({
        center: [patientCoordinates.longitude, patientCoordinates.latitude],
        duration: 1000,
      });
    }
  };

  const easeToBounds = () => {
    if (!planningMap) {
      return;
    }
    if (!boundsShowingAllPatients) {
      return;
    }

    const center = {
      lat:
        (boundsShowingAllPatients[0].lat + boundsShowingAllPatients[1].lat) / 2,
      lng:
        (boundsShowingAllPatients[0].lng + boundsShowingAllPatients[1].lng) / 2,
    };

    planningMap.easeTo({
      center,
      duration: 1000,
    });
  };

  const handleSelectionChange = (newSelectedKeys: Selection | null) => {
    if (
      newSelectedKeys === "all" ||
      (newSelectedKeys && newSelectedKeys.size > 0)
    ) {
      easeToPatientCoordinates(newSelectedKeys);

      const firstOccurrenceOrGroup =
        getOccurrenceOrGroupByFirstKey(newSelectedKeys);
      if (firstOccurrenceOrGroup) {
        setSelectedItem({
          item: firstOccurrenceOrGroup,
          type: "itemOnly",
        });
      }
    } else {
      easeToBounds();
      setSelectedItem(undefined);
    }
  };

  const getDisabledKeys = (
    unplannedOccurrencesAndGroups: IActivityOccurrenceOrGroup[],
  ): Selection | undefined => {
    if (!selectedItem) return;
    if (!isPendingAddToShift) return;
    if (!unplannedOccurrencesAndGroups) return undefined;
    if (selectedItem.type === "routeOnly") return;

    return new Set(selectedItem.item.id);
  };

  const selectedKeys: Set<Key> = new Set(
    selectedItem && selectedItem.type !== "routeOnly"
      ? "activityId" in selectedItem.item
        ? [getUniqueId(selectedItem.item)]
        : [selectedItem.item.id]
      : [],
  );

  if (isPending) {
    return <Loading message={t`Hämtar oplanerade aktiviteter`} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Gick inte att hämta oplanerade aktiviteter.`} ${deducedError(error)}`}
      />
    );
  }

  return (
    <>
      <div className={styles.planningSideBarHeader}>
        <Heading level="h2">
          <Trans>Att planera</Trans>
        </Heading>
        <PlainButton onClick={() => navigate(`activities/new`)}>
          <PlusIcon />
          <Trans>Ny aktivitet</Trans>
        </PlainButton>
      </div>
      <div className={styles.columnSelect}>
        <ControlledSelect
          value={grouping[0] ?? NO_GROUPING}
          onChange={(value) => setGrouping([value])}
          label={t`Gruppera på:`}
          size="compact"
          frame="borderless"
        >
          <option>{NO_GROUPING}</option>
          {GROUPABLE_COLUMNS.map((column) => {
            if (column === "area" && !areaEnabled) {
              return null;
            }
            return (
              <option key={column} value={column}>
                {_(COLUMN_DISPLAY_NAMES[column])}
              </option>
            );
          })}
        </ControlledSelect>
      </div>
      {unplannedOccurrencesAndGroups.length === 0 ? (
        <NoResults message={t`Inga aktiviteter att planera`} />
      ) : (
        <MedomaGridList
          aria-label={t`Oplanerade aktiviteter`}
          selectionMode="single"
          items={table.getRowModel().rows}
          onSelectionChange={handleSelectionChange}
          disabledKeys={getDisabledKeys(unplannedOccurrencesAndGroups)}
          dragAndDropHooks={dragAndDropHooks}
          dependencies={[selectedItem, unplannedOccurrencesAndGroups]}
          className={styles.unplannedOccurrencesAndGroups}
        >
          {(row) => <GroupedRows row={row} selectedKeys={selectedKeys} />}
        </MedomaGridList>
      )}
    </>
  );
};
