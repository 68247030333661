import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import {
  activityOccurrenceAndGroupKeys,
  useHomeVisitGroup,
} from "@/api/Activities";
import { deducedError } from "@/Utils/ErrorUtils";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { useNavigate, useParams } from "react-router";
import z from "zod";
import styles from "./GroupDetails.module.scss";
import { Heading } from "@components/Heading/Heading";
import { IconButton, PlainButton } from "@components/Button/Button";
import CrossIcon from "@components/icons/CrossIcon";
import { formattedTimeSpan } from "@/components/Time/timeUtils";
import { ActivityTitle } from "@/components/ActivityTitle/ActivityTitle";
import { Text } from "@components/Text/Text";
import { dateName } from "@/Utils/DateUtils";
import { ChangeGroup, CompetencesAndAssignees } from "@/forms/ChangeGroup";
import { useState } from "react";
import clsx from "clsx";
import type { IHomeVisitActivityOccurrence } from "@models/activities";
import {
  timeOfDayDictionary,
  timeOfDaySchema,
  visitStatusSchema,
} from "@models/activities";
import { TimeSpan } from "@/components/Time/TimeSpan";
import { getUnfulfilledRequirements } from "@/pages/commandcenter/helpers";
import { useLingui } from "@lingui/react";
import { getPatientNameWithStatus } from "@/api/Patients";
import { isUnfinishedOccurrence } from "@/forms/changeGroupUtils";
import ArrowLeftCurveIcon from "@components/icons/ArrowLeftCurveIcon";
import { StatusTag } from "@/components/StatusTag/StatusTag";
import { RemoveActivityOccurrence } from "../ActivityOccurrence/RemoveActivityOccurrence";
import { QuickActivityInDetails } from "@/pages/commandcenter/Planning/TimelineTile/QuickActivityInDetails";
import { endOfDay, isPast } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";

export const GroupDetails = ({
  showPatientName = false,
  showCloseButton = true,
}: {
  showPatientName?: boolean;
  showCloseButton?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { _ } = useLingui();
  const { groupId } = z.object({ groupId: z.string() }).parse(useParams());
  const { data: group, isPending, isError, error } = useHomeVisitGroup(groupId);
  const navigate = useNavigate();

  const [state, setState] = useState<
    | { mode: "view" }
    | { mode: "change-group"; occurrence: IHomeVisitActivityOccurrence }
  >({ mode: "view" });

  if (isPending) {
    return <Loading message={t`Hämtar besök`} padding={24} />;
  }

  if (isError) {
    return (
      <ErrorMessage
        message={`${t`Kunde inte hämta besöket.`} ${deducedError(error)}`}
      />
    );
  }

  const { patient, occurrences, start, end } = group;

  const endDayOfGroupHasPassed = isPast(endOfDay(group.end));

  const canAddQuickActivityToVisit =
    group.visitStatus !== visitStatusSchema.Values.finished &&
    !endDayOfGroupHasPassed;

  return (
    <article className={styles.container}>
      <div className={styles.topRow}>
        <div className={styles.highlightedInfo}>
          {showPatientName ? (
            <Heading level="h2" size="h1" className={styles.patientName}>
              {getPatientNameWithStatus(patient)}
            </Heading>
          ) : null}
          <Heading level="h2">
            <Trans>Hembesök</Trans>
          </Heading>
          <Text element="span" size="large">
            {dateName(start)},{" "}
            {group.timeOfDay === timeOfDaySchema.Values.Any
              ? _(timeOfDayDictionary.Any.long)
              : formattedTimeSpan(start, end)}
          </Text>
        </div>
        {showCloseButton ? (
          <IconButton
            aria-label={t`Stäng detaljvyn`}
            onClick={() => navigate("..")}
          >
            <CrossIcon />
          </IconButton>
        ) : null}
      </div>
      <ul className={styles.activities}>
        {occurrences.map((occurrence) => {
          const { start, end, timeOfDay, id, activityId } = occurrence;

          const occurrenceUnderChange =
            state.mode === "change-group" &&
            state.occurrence.id === id &&
            state.occurrence.activityId === activityId;

          return (
            <li
              key={`${activityId}${id}`}
              className={clsx(
                styles.activityOccurrence,
                occurrenceUnderChange && styles.occurrenceUnderChange,
              )}
            >
              <StatusTag status={occurrence.status} />
              <TimeSpan start={start} end={end} timeOfDay={timeOfDay} />
              <div>
                <ActivityTitle
                  activityOccurrence={occurrence}
                  linkTo={`../${occurrence.activityId}/occurrences/${occurrence.id}`}
                  weight="regular"
                  size="small"
                  alwaysShowDescription
                />
              </div>
              <CompetencesAndAssignees
                assignees={occurrence.assignees}
                unfulfilledRequirements={getUnfulfilledRequirements({
                  assignees: occurrence.assignees,
                  occurrences: [occurrence],
                })}
              />
              {isUnfinishedOccurrence(occurrence) ? (
                <>
                  <div className={styles.buttonWrapper}>
                    <RemoveActivityOccurrence
                      text={t`Ta bort tillfälle`}
                      occurrence={occurrence}
                      size={"medium"}
                      onSuccess={async () => {
                        if (!group) return;
                        // If the group still has occurrences, it stays in view, so we invalidate it to get the latest data.
                        if (group.occurrences.length >= 2) {
                          await queryClient.invalidateQueries({
                            queryKey:
                              activityOccurrenceAndGroupKeys.detail(groupId),
                          });
                          queryClient.invalidateQueries({
                            queryKey: activityOccurrenceAndGroupKeys.lists(),
                          });
                        } else {
                          // The group is now empty
                          // This component is always rendered in the context of a list.
                          // Once removal is done, we want to refresh the list, and then navigate to it.
                          // Notably, we don't invalidate the group detail, since that causes NotFound errors.
                          await queryClient.invalidateQueries({
                            queryKey: activityOccurrenceAndGroupKeys.lists(),
                          });
                          // Important to navigate back to the list URL since we can't display the detail view any more.
                          // See `index.tsx` for route structure.
                          await navigate("..");
                        }
                      }}
                    />
                  </div>
                  {occurrenceUnderChange ? (
                    <>
                      <div className={styles.buttonWrapper}>
                        <PlainButton
                          size="medium"
                          weight="light"
                          onClick={() => setState({ mode: "view" })}
                        >
                          <Trans>Avbryt</Trans>
                        </PlainButton>
                      </div>

                      <ChangeGroup
                        activityOccurrence={occurrence}
                        onSubmitSuccess={() => {
                          return occurrences.length === 1
                            ? navigate("..")
                            : setState({ mode: "view" });
                        }}
                      />
                    </>
                  ) : (
                    <div className={styles.buttonWrapper}>
                      <PlainButton
                        size="medium"
                        weight="light"
                        onClick={() =>
                          setState({ mode: "change-group", occurrence })
                        }
                      >
                        <ArrowLeftCurveIcon />
                        <Trans>Flytta</Trans>
                      </PlainButton>
                    </div>
                  )}
                </>
              ) : null}
            </li>
          );
        })}
      </ul>

      {canAddQuickActivityToVisit ? (
        <QuickActivityInDetails
          groupId={groupId}
          visitStatus={visitStatusSchema.Values.planned}
        />
      ) : null}
    </article>
  );
};
