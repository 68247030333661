import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useEffect, useState } from "react";
import type {
  IActivitySchedule,
  IRescheduleActivity,
  ITime,
} from "@/api/Activities";
import { ANY_TIME_OF_DAY, rescheduleActivity } from "@/api/Activities";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import { FormProvider, useForm } from "react-hook-form";
import type { IRecurrencePickerFields } from "@/forms/AddActivityForm/Scheduling/RecurrencePicker";
import { RecurrencePicker } from "@/forms/AddActivityForm/Scheduling/RecurrencePicker";
import {
  customRecurrenceIntervalUnitSchema,
  recurringNumberDictionary,
  recurringOptionSchema,
  type ICustomRecurrenceIntervalUnit,
} from "@/forms/AddActivityForm/Scheduling/schedulingModels";
import type { ITimeSlotsPickerFields } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { TimeSlotsPicker } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import {
  recurrencesPerDayOptionSchema,
  timespanToHours,
} from "@/forms/AddActivityForm/Scheduling/timeSlotsUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError } from "@/Utils/ErrorUtils";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import type { IWeekdaysPickerFields } from "@/forms/AddActivityForm/Scheduling/WeekdaysPicker";
import { WeekdaysPicker } from "@/forms/AddActivityForm/Scheduling/WeekdaysPicker";
import {
  getTimeFields,
  getTimespan,
} from "@/forms/AddActivityForm/activityTimeUtils";
import { timeOfDaySchema } from "@models/activities";
import * as Sentry from "@sentry/react";
import type { ICustomRecurringIntervalFields } from "@/forms/AddActivityForm/Scheduling/CustomRecurringInput";

type IEditScheduleFields = IRecurrencePickerFields &
  IWeekdaysPickerFields &
  ICustomRecurringIntervalFields &
  ITimeSlotsPickerFields;

export const EditSchedule = ({
  activityId,
  schedule,
  onSuccess,
}: {
  activityId: string;
  schedule: IActivitySchedule;
  onSuccess: () => void;
}) => {
  const [customIntervalUnit, setCustomIntervalUnit] =
    useState<ICustomRecurrenceIntervalUnit | null>(null);

  const scheduleTypeIsRecurring = "interval" in schedule || "days" in schedule;
  const scheduleTypeIsInterval = "interval" in schedule;
  const scheduleTypeIsWeekdays = "days" in schedule;

  const intervalAsWeeksOrDays = (interval: number) => {
    if (interval % 7 === 0 && interval >= 7) {
      setCustomIntervalUnit(customRecurrenceIntervalUnitSchema.Values.weeks);
      return interval / 7;
    }
    setCustomIntervalUnit(customRecurrenceIntervalUnitSchema.Values.days);
    return interval;
  };

  const times = scheduleTypeIsRecurring ? schedule.times : [];
  const isAnyTimeOfDay = times === ANY_TIME_OF_DAY;
  const hasTimeslots = !isAnyTimeOfDay && times.length > 0;

  const timeSensitivity = hasTimeslots
    ? timespanToHours(schedule.span)
    : undefined;

  const methods = useForm<IEditScheduleFields>({
    defaultValues: {
      recurrence: scheduleTypeIsWeekdays
        ? recurringOptionSchema.Values.onSelectedWeekdays
        : scheduleTypeIsInterval
          ? (recurringNumberDictionary[schedule.interval] ??
            recurringOptionSchema.Values.custom)
          : null,
      customRecurrenceInterval: scheduleTypeIsInterval
        ? intervalAsWeeksOrDays(schedule.interval).toString()
        : null,
      customRecurrenceIntervalUnit: customIntervalUnit,
      timeCategory: isAnyTimeOfDay
        ? timeOfDaySchema.Values.Any
        : timeOfDaySchema.Values.Specific,
      recurrencesPerDay: hasTimeslots
        ? recurrencesPerDayOptionSchema.parse(times.length.toString())
        : "1",
      timeslots: hasTimeslots ? times.map((time: ITime) => ({ time })) : [],
      timeSensitivity,
      weekdays: scheduleTypeIsWeekdays ? schedule.days : [],
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    reset,
    setError,
    unregister,
    watch,
  } = methods;

  const queryClient = useQueryClient();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: (formData: IRescheduleActivity) =>
      rescheduleActivity(activityId, formData),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries();
      onSuccess();
    },
  });

  const validateAndSubmit = handleSubmit((validatedFormData) => {
    const {
      recurrence,
      timeCategory,
      timeSensitivity,
      timeslots,
      weekdays,
      customRecurrenceInterval,
      customRecurrenceIntervalUnit,
    } = validatedFormData;

    if (recurrence === null) {
      Sentry.captureException("Failed to edit schedule. Recurrence is null.");
      return;
    }

    const isAnyTimeOfDay = timeCategory === timeOfDaySchema.Values.Any;

    const isRecurringAsInterval =
      recurrence !== recurringOptionSchema.Values.onSelectedWeekdays;

    const rescheduleActivityFields: IRescheduleActivity = {
      span: getTimespan({ isAnyTimeOfDay, timeSensitivity }),
      ...getTimeFields({
        isRecurringAsInterval,
        isAnyTimeOfDay,
        timeslots,
        weekdays,
        recurrence,
        customRecurrenceInterval,
        customRecurrenceIntervalUnit,
      }),
    };

    mutate(rescheduleActivityFields);
  });

  const isRecurringOnWeekdays =
    watch("recurrence") === recurringOptionSchema.Values.onSelectedWeekdays;

  useEffect(() => {
    if (!isRecurringOnWeekdays) {
      unregister("weekdays");
    }
  }, [isRecurringOnWeekdays, unregister]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={validateAndSubmit}>
        {isPending || isSuccess ? (
          <Loading message={t`Ändrar schemat`} />
        ) : errors.root?.server?.message && !isDirty ? (
          <ErrorMessage message={errors.root.server.message} />
        ) : (
          <></>
        )}
        <RecurrencePicker />
        {isRecurringOnWeekdays ? <WeekdaysPicker /> : <></>}
        <TimeSlotsPicker isRecurring />
        {isDirty ? (
          <FilledButton type="submit">
            <Trans>Spara ändringar</Trans>
          </FilledButton>
        ) : (
          <></>
        )}
      </Form>
    </FormProvider>
  );
};
