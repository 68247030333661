/* eslint-disable lingui/no-unlocalized-strings */
// Don't translate user-generated content
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/schedulingModels";
import { categorySchema, timeOfDaySchema } from "@models/activities";
import { medicalCompetenceSchema } from "@models/shifts";
import { allPatientTypes } from "@models/patients";
import { type ITemplate } from "../templateModels";
import { z } from "zod";
import { generateTemplateId } from "../templateUtils";
import { unitSchema } from "@models/units";

export const unitOgNvkSingleTemplateKeysSchema = z.enum([
  "prepareDosette",
  "ownMeasurementBloodSugar",
  "patientVitalsEvening",
  "patientVitalsMorning",
  "ownMeasurementWeight",
  "bloodSample",
  "giveMedicineOnce",
  "giveMedicineTwice",
  "fluidMeasurement",
]);

export type IUnitOgNvkSingleTemplateKey = z.infer<
  typeof unitOgNvkSingleTemplateKeysSchema
>;

export const templates: Record<IUnitOgNvkSingleTemplateKey, ITemplate> = {
  prepareDosette: {
    title: `Dosettdelning`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.ognvk,
      templateTitle: unitOgNvkSingleTemplateKeysSchema.Values.prepareDosette,
    }),
    templateRevision: 1,
  },
  ownMeasurementBloodSugar: {
    title: `Egen mätning blodsocker`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "7",
    timeslots: [
      { time: "07:30" },
      { time: "10:00" },
      { time: "11:30" },
      { time: "14:00" },
      { time: "18:00" },
      { time: "20:00" },
      { time: "23:00" },
    ],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodGlucose"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.ognvk,
      templateTitle:
        unitOgNvkSingleTemplateKeysSchema.Values.ownMeasurementBloodSugar,
    }),
    templateRevision: 1,
  },
  patientVitalsEvening: {
    title: `Egen mätning kväll`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "17:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodPressure", "pulse", "saturation", "temperature"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.ognvk,
      templateTitle:
        unitOgNvkSingleTemplateKeysSchema.Values.patientVitalsEvening,
    }),
    templateRevision: 1,
  },
  patientVitalsMorning: {
    title: `Egen mätning morgon`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "06:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodPressure", "pulse", "saturation", "temperature"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.ognvk,
      templateTitle:
        unitOgNvkSingleTemplateKeysSchema.Values.patientVitalsMorning,
    }),
    templateRevision: 1,
  },
  ownMeasurementWeight: {
    title: `Egen mätning vikt`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["weight"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.ognvk,
      templateTitle:
        unitOgNvkSingleTemplateKeysSchema.Values.ownMeasurementWeight,
    }),
    templateRevision: 1,
  },
  giveMedicineOnce: {
    title: `Ge medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      showNumberOfOccurrencesInHeader: true,
      patientTypes: allPatientTypes,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.ognvk,
      templateTitle: unitOgNvkSingleTemplateKeysSchema.Values.giveMedicineOnce,
    }),
    templateRevision: 1,
  },
  giveMedicineTwice: {
    title: `Ge medicin`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "2",
    timeslots: [{ time: "08:00" }, { time: "20:00" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      showNumberOfOccurrencesInHeader: true,
      patientTypes: allPatientTypes,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.ognvk,
      templateTitle: unitOgNvkSingleTemplateKeysSchema.Values.giveMedicineTwice,
    }),
    templateRevision: 1,
  },
  bloodSample: {
    title: `Provtagning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: null,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "08:00" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.ognvk,
      templateTitle: unitOgNvkSingleTemplateKeysSchema.Values.bloodSample,
    }),
    templateRevision: 1,
  },
  fluidMeasurement: {
    title: `Vätske- och urinmätning`,
    category: categorySchema.Values.PatientTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    customRecurrenceInterval: null,
    customRecurrenceIntervalUnit: null,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "06:30" }],
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
      showNumberOfOccurrencesInHeader: false,
    },
    templateId: await generateTemplateId({
      unit: unitSchema.Values.ognvk,
      templateTitle: unitOgNvkSingleTemplateKeysSchema.Values.fluidMeasurement,
    }),
    templateRevision: 1,
  },
};
